import React, { useState } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Chip, Button, Box } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import EditableField from './EditableField'; // Import EditableField

interface DocumentVersion {
    no: string;
    version: number;
    createdBy: string;
    status: string;
    isCurrent: boolean;
}

const documentVersions: DocumentVersion[] = [
    { no: "DOC-001", version: 3, createdBy: "Jan Kowalski", status: "Opiniowanie", isCurrent: true },
    { no: "DOC-001", version: 2, createdBy: "Anna Nowak", status: "Zatwierdzone", isCurrent: false },
    { no: "DOC-001", version: 1, createdBy: "Marek Wiśniewski", status: "Zatwierdzone", isCurrent: false }
];

const DocumentHistoryView: React.FC = () => {
    const [title, setTitle] = useState("Historia dokumentu");

    const handleOpenVersion = (version: number) => {
        alert(`Otwieranie wersji ${version}...`); // Możesz tu podmienić na rzeczywiste przejście do innej wersji
    };

    return (
        <Paper elevation={3} sx={{ padding: 2 }}>
            {/* Zmiana z Typography na EditableField */}
            <EditableField initialValue={title} onSave={setTitle} />
            
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Numer dokumentu</b></TableCell>
                            <TableCell><b>Wersja</b></TableCell>
                            <TableCell><b>Autor</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Akcje</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentVersions.map((doc) => (
                            <TableRow key={doc.version} selected={doc.isCurrent}>
                                <TableCell>{doc.no}</TableCell>
                                <TableCell>
                                    {doc.version} 
                                    {doc.isCurrent && (
                                        <Chip 
                                            label="Bieżąca wersja" 
                                            color="primary" 
                                            size="small" 
                                            icon={<CheckCircle />} 
                                            sx={{ marginLeft: 1 }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>{doc.createdBy}</TableCell>
                                <TableCell>
                                    <Chip 
                                        label={doc.status} 
                                        color={doc.isCurrent ? "warning" : "success"} 
                                        variant="outlined" 
                                    />
                                </TableCell>
                                <TableCell>
                                    {!doc.isCurrent && (
                                        <Button variant="outlined" size="small" onClick={() => handleOpenVersion(doc.version)}>
                                            Otwórz wersję
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default DocumentHistoryView;
