import { Localized } from "@fluent/react";
import { DeviceValidationStatus } from "../supplierDeviceInstanceApi";
import { Chip } from "@mui/material";

export const SupplierDeviceValidationStatusLabel = ({ status, styled = true }
    : { status: DeviceValidationStatus; styled?: boolean; }) => {

    const statusMapping: Record<
        DeviceValidationStatus,
        { color: string; textColor: string; labelId: string }
    > = {
        NOT_VALIDATED: {
            color: "bg-red-200",
            textColor: "#dc2626",
            labelId: "validation-status-not-validated",
        },
        VALIDATED: {
            color: "bg-green-200",
            textColor: "#16a34a",
            labelId: "validation-status-validated",
        },
        INPROGRESS: {
            color: "bg-blue-200",
            textColor: "#2563eb",
            labelId: "validation-status-inprogress",
        },
    };
    const mapping = statusMapping[status];

    if (!mapping) {
        return <span></span>;
    }

    return styled ?
        (
            <span
            className={`inline-block px-2 py-0.5  text-sm rounded  whitespace-nowrap ${mapping.color}`}
        >
            <Localized id={mapping.labelId} />
        </span>
        ) :
        (
            <Chip
                variant="outlined"
                label={<Localized id={mapping.labelId} />}
                sx={{
                    color: mapping.textColor,
                    borderColor: mapping.textColor, 
                }}
            />
        );
};
