import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField, Paper } from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';

const initialFields = {
    documentNumber: "FS/01/01-15",
    documentTitle: "Specyfikacja Funkcjonalna Systemu",
    fields: [
        { id: "1", label: "Wprowadzenie", value: "<p>Wprowadzenie do dokumentu...</p>" },
        { id: "4", label: "Opis", value: "<p>Opis systemu...</p>" },
        { id: "4.1", label: "Cel", value: "<p>Cel tego dokumentu...</p>" },
        { id: "4.3", label: "Powiązane dokumenty", value: "<p>Lista powiązanych dokumentów...</p>" },
        { id: "4.4", label: "Abbreviations", value: "<p>Lista skrótów użytych w dokumencie...</p>" },
        { id: "4.5", label: "Standardy", value: "<p>Obowiązujące standardy...</p>" },
        { id: "4.6", label: "Narzędzia", value: "<p>Wymagane narzędzia...</p>" }
    ]
};

const DocumentDescriptionView: React.FC = () => {
    const [fields, setFields] = useState(initialFields);
    const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});
    
    const handleEditClick = (id: string) => {
        setIsEditing(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleChange = (id: string, value: string) => {
        setFields(prev => ({
            ...prev,
            fields: prev.fields.map(field => field.id === id ? { ...field, value } : field)
        }));
    };

    return (
        <Paper sx={{ padding: 3 }}>
            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="h6">Numer dokumentu:</Typography>
                <Typography variant="body1" sx={{ ml: 2 }}>{fields.documentNumber}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="h6">Tytuł dokumentu:</Typography>
                <Typography variant="body1" sx={{ ml: 2 }}>{fields.documentTitle}</Typography>
            </Box>
            <Box mt={3}>
                {fields.fields.map(({ id, label, value }) => (
                    <Box key={id} mb={3}>
                        <Typography variant="h6">{id} {label}</Typography>
                        <Box display="flex" alignItems="center">
                            {isEditing[id] ? (
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    value={value}
                                    onChange={(e) => handleChange(id, e.target.value)}
                                />
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: value ?? "" }}></div>
                            )}
                            <IconButton onClick={() => handleEditClick(id)}>
                                {isEditing[id] ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Paper>
    );
};

export default DocumentDescriptionView;
