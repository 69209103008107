import { alpha, styled } from '@mui/material/styles';
import React, { useContext, useState } from "react";
import { TreeItem, TreeItemProps, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from '@mui/x-tree-view';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { SpecComponent, SpecWithDetails } from './specSlice';
import { useSearchParams } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { DevSpecDialogContext } from './EditSpec';

const CustomTreeItem = React.forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} ref={ref} />
    ),
)

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.label}:hover`]: {
      '& button': {
          visibility: 'visible',
      }
  },
  [`& .${treeItemClasses.label}`]: {
      '& button': {
          visibility: 'hidden',
      }
  },
}));

type Renderer = (c: SpecComponent) => JSX.Element

interface SpecItemsProps {
    data: SpecWithDetails
    selectedItemId : string | undefined
    onSelectionChange: (newId: string) => void
    render? : Renderer
    readonly? : boolean
}
export const SpecItemsTree = (props: SpecItemsProps) => {
    const { data, onSelectionChange, readonly, render } = props

    return <SimpleTreeView
        slots = {{ collapseIcon: ArrowDropDownIcon, expandIcon: ArrowRightIcon }}
        onItemSelectionToggle={(_, id, isSelected) => {
            if (isSelected) {
                onSelectionChange(id)
            }
        }}
        selectedItems = {props.selectedItemId}
    >
        {data.components.map(c => <SpecComponentItem c={c} readonly={readonly ?? false} render={render} />)}
    </SimpleTreeView>
}

const SpecComponentItem = (props: { c: SpecComponent, readonly: boolean, render?: Renderer }) => {
    const { c, render } = props
    return <>
        <StyledTreeItem itemId={c.id.toString()} label={<ComponentTreeItem component={c} readonly={props.readonly} render={render} />}>
            {c.childComponents.map(cc => <SpecComponentItem key={cc.id} c={cc} readonly={props.readonly} render={render} />)}
        </StyledTreeItem>
    </>
}

function defaultRender(c: SpecComponent): JSX.Element {
    const { numeration, name } = c
    return numeration ?
        <><span><i>{numeration}.</i></span>&nbsp;<span>{name}</span></> :
        <span>{name}</span>

}

const ComponentTreeItem = ({ component, readonly, render }: { component: SpecComponent, readonly: boolean, render?: Renderer }) => {
    const dialog = useContext(DevSpecDialogContext)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const r = render ?? defaultRender

    return <div className='flex flex-row'>
        {r(component)}
        {!readonly && <IconButton onClick={e => { e.stopPropagation(); handleClick(e); }} sx={{ p: 0, ml: 'auto' }}><MoreVertIcon fontSize='small' /></IconButton>}
        {!readonly && <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
            paper: {
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
        <MenuItem onClick={e => {
                e.stopPropagation();
                setAnchorEl(null);
                dialog({ tag: 'edit-component', data: component, });
            } }>Edit</MenuItem>
        <MenuItem onClick={e => {
                e.stopPropagation();
                setAnchorEl(null);
                dialog({ tag: 'delete-component', component, });
            } }>Delete</MenuItem>
        <MenuItem onClick={e => {
                e.stopPropagation()
                setAnchorEl(null)
                dialog({ tag: 'add-child-component', parentComponentId: component.id, specId: component.deviceSpecificationId })
            }}>Add subcomponent</MenuItem>
    </Menu>}
    </div>
}
