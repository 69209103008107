import React, { useState } from 'react';
import { Paper, Typography, TextField, Box, IconButton } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { RichTextEditor } from '../../app/RichTextEditor';

interface DescriptionViewProps {
    number: string;
    title: string;
}

const DescriptionView: React.FC<DescriptionViewProps> = ({ number, title }) => {
    const [description, setDescription] = useState("");
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editableTitle, setEditableTitle] = useState(title);
    const [tempTitle, setTempTitle] = useState(title);
    const [isHovered, setIsHovered] = useState(false);

    const handleTitleClick = () => {
        setIsEditingTitle(true);
        setTempTitle(editableTitle);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempTitle(event.target.value);
    };

    const handleApprove = () => {
        setEditableTitle(tempTitle);
        setIsEditingTitle(false);
    };

    const handleCancel = () => {
        setTempTitle(editableTitle);
        setIsEditingTitle(false);
    };

    return (
        <Paper elevation={3} sx={{ padding: 2 }}>
            <Box display="flex" alignItems="center" gap={1} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <Typography variant="h5" color="primary">
                    {number}. 
                </Typography>
                {isEditingTitle ? (
                    <>
                        <TextField
                            value={tempTitle}
                            onChange={handleTitleChange}
                            autoFocus
                            variant="standard"
                            fullWidth
                        />
                        <IconButton color="success" onClick={handleApprove}>
                            <CheckIcon />
                        </IconButton>
                        <IconButton color="error" onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </>
                ) : (
                    <Box display="flex" alignItems="center" gap={1} sx={{ cursor: 'pointer' }} onClick={handleTitleClick}>
                        <Typography
                            variant="h5"
                            color="primary"
                            sx={{
                                transition: "0.2s",
                                ...(isHovered && { textDecoration: "underline", color: "secondary.main" })
                            }}
                        >
                            {editableTitle}
                        </Typography>
                        {isHovered && (
                            <IconButton size="small" color="primary">
                                <EditIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>
            <RichTextEditor value={description} onValueChange={setDescription} label="Change description" />
        </Paper>
    );
};

export default DescriptionView;
