import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import UploadIcon from '@mui/icons-material/Upload';

interface ImportButtonProps {
    loading: boolean;
    onClick: () => void;
}

export const ImportButton: React.FC<ImportButtonProps> = ({ loading, onClick }) => {
    return (
        <LoadingButton
            loading={loading}
            size="small"
            color="success"
            variant="contained"
            startIcon={<UploadIcon />}
            onClick={onClick}
        >
            <Localized id="import">_Import</Localized>
        </LoadingButton>
    );
};
