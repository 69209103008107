import AddIcon from '@mui/icons-material/Add'
import { Fab } from "@mui/material";

interface AddFabButtonProps {
    onClick: () => void;
}

export const AddFabButton: React.FC<AddFabButtonProps> = ({onClick }) => {
    return (
        <Fab onClick={() => onClick()} color="secondary" size="small" sx={{ m: 1 }}>
            <AddIcon />
        </Fab>
    );
};
