import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useDataGridCustomLocaleText } from "../../../app/hooks"
import { useEffect, useState } from "react"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Button, ButtonGroup, Paper, TableContainer } from "@mui/material"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { getStructureName } from "../../device/Structure"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { DocumentHeader, loadDeletedDocuments } from "../documentsApi"
import { DocumentStatusLabel } from "../DocumentStatusLabel"
import { unwrapResult } from "@reduxjs/toolkit"
import { RestoreDocumentDialog } from "./dialogs/RestoreDocumentDialog"
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForever from '@mui/icons-material/DeleteForever';
import SmallLabeledBadge from "../../../app/components/SmallLabeledBadge"
import { PermamentDocumentDeleteDialog } from "./dialogs/PermamentDocumentDeleteDialog"
import produce from "immer";


export const DeletedDocumentsList = () => {
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [documents, setDocuments] = useState<DocumentHeader[]>([]);
    const localeText = useDataGridCustomLocaleText();
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [restoreConfirmationVisible, setRestoreConfirmationVisible] = useState<boolean>(false);
    const [permamentDeleteConfirmationVisible, setPermamentDeleteConfirmationVisible] = useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] = useState<string>("");

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadDeletedDocuments())
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => { setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }, [])

    const handleRestoreDocumentClick = (id: string) => {
        setRestoreConfirmationVisible(true);
        setSelectedDocument(id)
    }

    const handlePermamentDeleteClick = (id: string) => {
        setPermamentDeleteConfirmationVisible(true);
        setSelectedDocument(id)
    }

    const onSuccessfullyRestored = async () => {
        setRestoreConfirmationVisible(false);
        setDocuments(
            produce(draft => {
                const index = draft.findIndex(doc => doc.id === selectedDocument);
                if (index !== -1) {
                    draft.splice(index, 1);
                }
            })
        );
    };

    const onSuccessfullyDeleted = async () => {
        setPermamentDeleteConfirmationVisible(false);
        setDocuments(
            produce(draft => {
                const index = draft.findIndex(doc => doc.id === selectedDocument);
                if (index !== -1) {
                    draft.splice(index, 1);
                }
            })
        );
    };

    const onRestoreCanceled = () => {
        setSelectedDocument("")
        setRestoreConfirmationVisible(false);
    };

    const onDeleteCanceled = () => {
        setSelectedDocument("")
        setPermamentDeleteConfirmationVisible(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'no',
            headerName: l10n.getString('number', null, 'Numer'),
            width: 100,
        },
        {
            field: 'title',
            headerName: l10n.getString('title', null, 'Title'),
            flex: 1
        },
        {
            field: 'companyName',
            headerName: l10n.getString('company', null, 'Firma'),
            width: 200,
        },
        {
            field: 'createdByFullName',
            headerName: l10n.getString('created-by', null, 'Utworzył'),

        },
        {
            field: 'createDate',
            headerName: l10n.getString('project-create-date', null, 'Data Utworzenia'),

            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'structureId',
            headerName: l10n.getString("structure", null, "_structure"),
            minWidth: 200,
            valueGetter: (_, row) => {
                return row.structureId ? getStructureName(row.structureId) : "";
            }
        },
        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <DocumentStatusLabel status={params.row.status ?? ""} />
                    {params.row.isDeleted && <SmallLabeledBadge label="DELETED" />}
                </div>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            width: 400,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button startIcon={<RestoreIcon />} onClick={() => handleRestoreDocumentClick(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="restore">
                                <span>_Przywróć</span>
                            </Localized>
                        </Button>
                        <Button startIcon={<DeleteForever />} onClick={() => handlePermamentDeleteClick(params.row.id)} color="error" variant="outlined">
                            <Localized id="delete">
                                <span>_Permament delete</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    return (
        <>
            <TableContainer component={Paper}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            style={{ minHeight: 100, height: 'calc(100vh - 250px)' }}
                            localeText={localeText}
                            rows={documents}
                            columns={columns}
                            slots={{ toolbar: QuickSearchToolbar }} />
                    </div>
                </div>
            </TableContainer>
            {
                restoreConfirmationVisible &&
                <RestoreDocumentDialog
                    documentId={selectedDocument}
                    onSuccess={onSuccessfullyRestored}
                    onCancel={onRestoreCanceled}
                />
            }
            {
                permamentDeleteConfirmationVisible &&
                <PermamentDocumentDeleteDialog
                    documentId={selectedDocument}
                    onSuccess={onSuccessfullyDeleted}
                    onCancel={onDeleteCanceled}
                />
            }
        </>
    )
}
