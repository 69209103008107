import { Localized } from "@fluent/react";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { AppTextFieldHandler } from "../../../../app/appTypes";
import { useAppDispatch } from "../../../../app/hooks";
import { restoreDeletedDocument } from "../../documentsApi";
import { showError, showSuccess } from "../../../notifications/notificationsSlice";
import { CancelButton } from "../../../../app/components/CancelButton";
import { RestoreButton } from "../../../../app/components/RestoreButton";

interface RestoreDocumentDialogProps {
    documentId: string;
    onSuccess: () => Promise<void>;
    onCancel: () => void;
}

export const RestoreDocumentDialog: React.FC<RestoreDocumentDialogProps> = ({
    documentId,
    onSuccess,
    onCancel }) => {
    const dispatch = useAppDispatch()

    const [saving, setSaving] = useState(false);
    const [password, setPassword] = useState("")
    const [serverError, setServerError] = useState("")

    const handlePasswordFieldChange: AppTextFieldHandler = useCallback(e => {
        setPassword(e.target.value)
    }, [])


    const handleConfirmClick = async () => {
        if (password && documentId) {
            setSaving(true)
            try {
                const result = await dispatch(restoreDeletedDocument({
                    documentId: documentId,
                    password: password
                }));
                if (restoreDeletedDocument.fulfilled.match(result)) {
                    dispatch(showSuccess("restored"))
                    onSuccess()
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                            setServerError(errorMsg)
                        }
                    }
                }
            }
            catch (error) {

                dispatch(showError("error"))
            }
            setSaving(false)
        }
    }

    return (
        <Dialog maxWidth="sm" open={true} onClose={onCancel} fullWidth >
            <DialogTitle>
                <Localized id="document-restore-deleted-confirmation">
                    <span>_Czy napewno chcesz przywrócić dokument?</span>
                </Localized>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Localized id="document-restore-deleted-confirmation-details">
                        <span>_detale</span>
                    </Localized>
                </DialogContentText>
                <TextField
                    autoFocus
                    fullWidth
                    sx={{ marginTop: 2 }}
                    InputLabelProps={{ shrink: true }}
                    type="password"
                    value={password}
                    onChange={handlePasswordFieldChange}
                    label={<Localized id="password"><span>Hasło</span></Localized>}
                    helperText={<Localized id="autorization-password-required"><span>Wymagana autoryzacja hasłem</span></Localized>}
                    autoComplete="off"
                    aria-required="true"
                />
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <RestoreButton loading={saving} onClick={handleConfirmClick}></RestoreButton>
                <CancelButton loading={saving} onClick={onCancel}></CancelButton>
            </DialogActions>
        </Dialog>
    );
};
