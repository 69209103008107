import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import DeleteForever from '@mui/icons-material/DeleteForever';

interface DeleteButtonProps {
    loading: boolean;
    onClick: () => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ loading, onClick }) => {
    return (
        <LoadingButton
            loading={loading}
            size="small"
            color="error"
            variant="contained"
            startIcon={<DeleteForever />}
            onClick={onClick}
        >
            <Localized id="delete">_delete</Localized>
        </LoadingButton>
    );
};
