import React from 'react';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, Avatar, Box } from '@mui/material';
import BadgeAvatar from './BadgeAvatar';

interface ApprovalRecord {
    id: string;

    firstName: string;
    lastName: string;
    position: string;
    role: string;
    reviewDate?: string;
    approvalDate?: string;
    status: 'In Progress' | 'Completed';
}

const approvalData: ApprovalRecord[] = [
    { id: '0e76cadc-c75d-4178-86e3-076133185d22', firstName: 'Jan', lastName: 'Kowalski', position: 'Kierownik', role: 'Author', reviewDate: '2024-01-10', approvalDate: '2024-01-15', status: 'Completed' },
    { id: '2', firstName: 'Anna', lastName: 'Nowak', position: 'Specjalista', role: 'Edytor', reviewDate: '2024-01-11', approvalDate: '2024-01-16', status: 'Completed' },
    { id: '3', firstName: 'Marek', lastName: 'Wiśniewski', position: 'Analityk', role: 'Opiniujący', reviewDate: '2024-01-12', status: 'Completed' },
    { id: '4', firstName: 'Ewa', lastName: 'Zielińska', position: 'Kierownik Działu', role: 'Zatwierdzający', reviewDate: '2024-01-13', approvalDate: '2024-01-17', status: 'Completed' },
    { id: '5', firstName: 'Piotr', lastName: 'Dąbrowski', position: 'Dyrektor', role: 'Zatwierdzający', reviewDate: '2024-01-14', approvalDate: '2024-01-18', status: 'In Progress' },
];

const getStatusColor = (status: string) => {
    return status === 'In Progress' ? 'error' : 'info';
};

const roleColors: Record<string, 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'info'> = {
    'Author': 'success',
    'Edytor': 'primary',
    'Opiniujący': 'warning',
    'Zatwierdzający': 'error',
};

const ApprovalCardView: React.FC = () => {
    return (
        <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5" color="primary" gutterBottom>
                Karta zatwierdzeń
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Użytkownik</TableCell>
                            <TableCell>Stanowisko</TableCell>
                            <TableCell>Rola</TableCell>
                            <TableCell>Data Opiniowania</TableCell>
                            <TableCell>Data Zatwierdzenia</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {approvalData.map((record, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        <BadgeAvatar firstName={record.firstName} lastName={record.lastName} userId={record.id} />
                                        {record.firstName} {record.lastName}
                                    </Box>
                                </TableCell>
                                <TableCell>{record.position}</TableCell>
                                <TableCell>
                                    <Chip label={record.role} color={roleColors[record.role] || 'default'} size="small" variant='outlined'/>
                                </TableCell>
                                <TableCell>{record.reviewDate || '-'}</TableCell>
                                <TableCell>{record.approvalDate || '-'}</TableCell>
                                <TableCell>
                                    <Chip label={record.status} color={getStatusColor(record.status)} size="small" />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ApprovalCardView;
