import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";

interface CancelButtonProps {
    loading: boolean;
    onClick: () => void;
}

export const CancelButton: React.FC<CancelButtonProps> = ({ loading, onClick }) => {
    return (
        <LoadingButton
            loading={loading}
            size="small"
            color="primary"
            variant="outlined"
            onClick={onClick}
        >
            <Localized id="cancel">_cancel</Localized>
        </LoadingButton>
    );
};
