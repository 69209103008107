import { StructureName } from "../device/Structure"
import { Urs } from "./UrsSlice"
import { DocumentStatusLabel } from "../documents/DocumentStatusLabel"
import { DocumentStatus } from "../documents/documentsApi"


interface CommonProps {
    className?: string;
    children: React.ReactNode;
}

export type HeaderLabelProps = CommonProps;
export type HeaderFieldProps = CommonProps;

const HeaderLabel = ({ className = "", children }: CommonProps) => {
    return (
        <div
            className={`w-32 text-xs font-bold px-2 py-1 bg-gray-100 border border-gray-300 ${className}`}
        >
            {children}
        </div>
    );
};

const HeaderField = ({ className = "", children }: CommonProps) => {
    return (
        <div
            className={`w-32 text-md px-2 py-1 border border-gray-300 ${className}`}
        >
            {children}
        </div>
    );
};

const FlexHeaderField = ({ className = "", children }: CommonProps) => {
    return (
        <div
            className={`flex-1 text-md px-2 py-1 border border-gray-300 ${className}`}
        >
            {children}
        </div>
    );
};
export const ImportedSupplierUrsHeader = ({ doc }: { doc: Urs }) => {
    const { ursNo, version, title, status, relatedCompanyName, createDate, deviceTypeId } = doc

    const getColor = (status) => {
        switch (status) {
            case 'N':
                return 'bg-green-200';
            default:
                return 'bg-red-200';
        }
    };

    return <>
        <div className="border border-gray-300 rounded shadow">
            <div className="flex">
                <HeaderLabel className="w-48">Numer</HeaderLabel>
                <HeaderLabel className="w-16 text-center">Wersja</HeaderLabel>
                <HeaderLabel className="flex-1">Tytuł</HeaderLabel>
                <HeaderLabel className="w-64">Właściciel dokumentu</HeaderLabel>
                <HeaderLabel className="w-64">Struktura</HeaderLabel>
                <HeaderLabel className="w-48">Status</HeaderLabel>
            </div>
            <div className="flex border-t border-gray-300">
                <HeaderField className="w-48 font-bold">{ursNo}</HeaderField>
                <HeaderField className="w-16   text-center">
                    {version + 1}
                </HeaderField>
                <FlexHeaderField>{title}</FlexHeaderField>
                <FlexHeaderField>{relatedCompanyName}</FlexHeaderField>
                <HeaderField className="w-64">
                    <StructureName structureId={deviceTypeId} />
                </HeaderField>
                <HeaderField className="w-48">
                    <span
                        className={`inline-block px-2 py-1 rounded bg-red-100 ${getColor(status)}`}
                    >
                        <DocumentStatusLabel status={status as DocumentStatus} />
                    </span>
                </HeaderField>
            </div>
        </div>
    </>
}