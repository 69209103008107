import { Button, Chip, IconButton, Stack } from "@mui/material";
import { StandardFilterMenu } from "./StandardFilterMenu";
import { DateFilterMenu } from "./DateFilterMenu";
import { Localized, useLocalization } from "@fluent/react";
import SyncIcon from '@mui/icons-material/Sync';
import { Dispatch, SetStateAction } from "react";
import { URSListFilters } from "./types";
import { defURSFilters } from "./helper";

interface DocumentFilterPanelProps {
    filters: URSListFilters
    onFilterChange: Dispatch<SetStateAction<URSListFilters>>
    reload: () => void
}

export const DocumentFilterPanel = ({ filters, onFilterChange, reload }:DocumentFilterPanelProps) => {
    const { l10n } = useLocalization();
    

    const handleSubmit = (date) => {
        onFilterChange(prevFilters => ({ 
            ...prevFilters,
            date: { from: date.dateFrom, to: date.dateTo }
        }));
    }

    const updateOption = (type: string, name:string) => {
        console.log(name)
        if(type === "status") {
            onFilterChange(prevFilters => ({
                ...prevFilters,
                statusOptions: prevFilters.statusOptions.map(item =>
                    item.uniqueName === name ? { ...item, selected: !item.selected } : item
                )
            }))
        } else if(type === "myRole") {
            if(name === "IN") {
                const selectedINOption = filters.myRoleOptions.find(f => f.uniqueName === "IN")?.selected;
                onFilterChange(prevFilters => ({
                    ...prevFilters,
                    myRoleOptions: prevFilters.myRoleOptions.map(item => (
                        { ...item, selected: !selectedINOption }
                    ))
                }))
            } else {
                onFilterChange(prevFilters => ({
                    ...prevFilters,
                    myRoleOptions: prevFilters.myRoleOptions.map(item => (
                        item.uniqueName === name ? { ...item, selected: !item.selected } : item
                    ))
                }))
            }
        }
    }

    const handleClearDateFilterClick = () => {
        onFilterChange(prevFilters => ({ 
            ...prevFilters,
            date: { from: "all", to: "all" }
        }));
    }

    const handleRefreshClick = () => {
        reload()
    }

    const handleClearFiltersClick = () => {
        onFilterChange(prevFilters => ({ 
            date: { from: "all", to: "all" },
            statusOptions: prevFilters.statusOptions.map(item => ({
                ...item,
                selected: false
            })),
            myRoleOptions: prevFilters.myRoleOptions.map(item => ({
                ...item,
                selected: false
            }))
        }));

        onFilterChange(defURSFilters)
    }

    return (
        <>
            <Stack sx={{ paddingBottom: 1 }}>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <DateFilterMenu allowAllOption defaultOption="all" currentOption={filters.date.from === "all" ? "all" : undefined} onSubmit={handleSubmit}/>
                    <StandardFilterMenu label="Status" multiple options={filters.statusOptions} onUpdate={(name) => updateOption("status", name)}></StandardFilterMenu>
                    {filters.myRoleOptions.length > 0 && <StandardFilterMenu label={l10n.getString("filters-my-role", null, "_Moja rola")} multiple options={filters.myRoleOptions} onUpdate={(name) => updateOption("myRole", name)}></StandardFilterMenu>}
                    <IconButton
                        onClick={handleRefreshClick}>
                        <SyncIcon />
                    </IconButton>
                </Stack>
                <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
                    {filters.date.from !== "all" ? <Chip label={new Date(filters.date.from).toLocaleDateString() + " - " + new Date(filters.date.to).toLocaleDateString()} size="small" onDelete={handleClearDateFilterClick}/> : null}
                    {filters.statusOptions.filter(f => f.selected).map((option) =>
                        <Chip label={<span><strong>Status: </strong>{option.translationCode ? <Localized id={option.translationCode}>{option.name}</Localized> : option.name}</span>} size="small" onDelete={() => updateOption("status", option.uniqueName)}/>
                    )}
                    {filters.myRoleOptions.filter(f => f.selected).map((option) =>
                        <Chip label={<span><strong><Localized id="filters-my-role">_Moja rola</Localized>: </strong>{option.translationCode ? <Localized id={option.translationCode}>{option.name}</Localized> : option.name}</span>} size="small" onDelete={() => updateOption("myRole", option.uniqueName)}/>
                    )}
                    {filters.statusOptions.some(o => o.selected) || filters.myRoleOptions.some(o => o.selected) || filters.date.from !== "all" ? <Button size="small" variant="text" onClick={handleClearFiltersClick}>
                        <Localized id="filters-clear-all-filters">_Wyczyść filtry</Localized>
                    </Button> : null}
                </Stack>
            </Stack>
        </>
    )
}
