import { useState, useEffect, Dispatch, SetStateAction } from "react";

function getStorageValue<T>(key: string, defaultValue: T) {
    const item = localStorage.getItem(key);
    if (item !== null) {
        try {
            return JSON.parse(item) as T
        } catch (error) {
            console.error(`Cannot load data from local storage. Data replaced with default value.`);
            localStorage.setItem(key, JSON.stringify(defaultValue))
            return defaultValue
        }
    } else {
        localStorage.setItem(key, JSON.stringify(defaultValue))
        return defaultValue
    }
}

export const useLocalStorage = <T>(key:string , defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
    const [value, setValue] = useState(() => {
        return getStorageValue<T>(key, defaultValue);
    });

    useEffect(() => {    
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    
    return [value, setValue];
};
