import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import RestoreIcon from "@mui/icons-material/Restore";

interface RestoreButtonProps {
    loading: boolean;
    onClick: () => void;
}

export const RestoreButton: React.FC<RestoreButtonProps> = ({ loading, onClick }) => {
    return (
        <LoadingButton
            loading={loading}
            size="small"
            color="success"
            variant="contained"
            startIcon={<RestoreIcon />}
            onClick={onClick}
        >
            <Localized id="restore">Zapisz</Localized>
        </LoadingButton>
    );
};
