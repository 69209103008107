import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { ChangeEvent, useCallback, useState } from "react"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { selectAllUrsOffers } from "../../ursOffer/ursOfferSlice"
import { createSupplierDeviceInstance, SupplierDeviceInstance } from "../supplierDeviceInstanceApi"
import { DeviceTypeCombobox } from "../../device/DeviceTypeCombobox"
import { AppId } from "../../../app/appTypes"
import { SaveButton } from "../../../app/components/SaveButton"
import { CancelButton } from "../../../app/components/CancelButton"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface CreateSupplierDeviceInstanceDialogProps {
    onSucces: (device: SupplierDeviceInstance) => void
    onCancel: () => void
}

export const CreateSupplierDeviceInstanceDialog = (props: CreateSupplierDeviceInstanceDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)
    const [registerDevice, setRegisterDevice] = useState(false);
    const [serialNo, setSerialNo] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [deviceTypeId, setDeviceTypeId] = useState<AppId>("")
    const [model, setModel] = useState<AppId>("")
    const [productionYear, setProductionYear] = useState<number>(2025);
    const [description, setDescription] = useState("");

    const allOffers = useAppSelector(selectAllUrsOffers);

    const serialNoError = validationErrors && serialNo === ""
    const deviceNameError = validationErrors && deviceName === ""

    const handleSerialNoChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSerialNo(e.target.value);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDeviceName(e.target.value);
    };

    const handleModelChange = (e: ChangeEvent<HTMLInputElement>) => {
        setModel(e.target.value);
    };

    const handleProductionYearChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProductionYear(parseInt(e.target.value));
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleDeviceTypeIdChange = useCallback(structureId => setDeviceTypeId(structureId), [setDeviceTypeId])


    const handleSaveClick = async () => {
        setValidationErrors(true)
        if (serialNo && deviceName) {
            try {
                setSaving(true)

                const result =
                    await dispatch(createSupplierDeviceInstance({
                        serialNo: serialNo,
                        name: deviceName,
                        deviceTypeId: deviceTypeId,
                        model: model,
                        productionYear: productionYear,
                        description: description
                    }))

                if (createSupplierDeviceInstance.fulfilled.match(result)) {
                    const dev = result.payload as SupplierDeviceInstance;
                    dispatch(showSuccess("saved"))
                    props.onSucces(dev)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle><Localized id='devices-new'>_New device</Localized></DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{ padding: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        value={serialNo}
                        error={serialNoError}
                        helperText={serialNoError && <RequiredError />}
                        onChange={handleSerialNoChange}
                        label={<Localized id="devices-serial-number">Numer fabryczny</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={deviceNameError}
                        helperText={deviceNameError && <RequiredError />}
                        multiline={true}
                        inputProps={{ maxLength: 200 }}
                        value={deviceName}
                        onChange={handleNameChange}
                        label={<Localized id="devices-name">Nazwa urzadzenia</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <DeviceTypeCombobox
                        onlyLeafs={true}
                        fullWidth
                        required
                        error={deviceTypeId === ''}
                        helperText={deviceTypeId === '' && <RequiredError />}
                        value={deviceTypeId}
                        onChange={handleDeviceTypeIdChange}
                        label={<Localized id="structure">Struktura</Localized>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={model}
                        onChange={handleModelChange}
                        label={<Localized id="devices-model">Model</Localized>}>
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        type="number"
                        onChange={handleProductionYearChange}
                        value={productionYear}
                        onInput={(e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 4) }}
                        label={<Localized id="devices-production-year">Rok produkcji</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={description}
                        onChange={handleDescriptionChange}
                        multiline
                        minRows={4}
                        label={<Localized id="devices-description">Opis</Localized>}>
                    </TextField>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <SaveButton loading={saving} onClick={handleSaveClick}></SaveButton>
            <CancelButton loading={saving} onClick={props.onCancel}></CancelButton>
        </DialogActions>
    </Dialog>
}
