import { useEffect, useState } from "react"
import { unwrapResult } from "@reduxjs/toolkit"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { Button, ButtonGroup, Paper, TableContainer } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { selectSettings } from "../../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { DocumentStatusLabel } from "../DocumentStatusLabel"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"
import { DocumentHeader, loadImportedDocumentsByFilters } from "../documentsApi"
import { ImportSupplierUrsDialog } from "./ImportSupplierUrsDialog"
import { FlexDiv } from "../../../app/Utils"
import { AddFabButton } from "../../../app/components/AddFabButton"
import NewSmallBadge from "../../../app/components/NewSmallBadge"

export const ImportedSupplierUrsList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { locale } = useAppSelector(selectSettings);
    const [documents, setDocuments] = useState<DocumentHeader[]>([]);
    const { l10n } = useLocalization()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [createDialogVisible, setCreateDialogVisible] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadImportedDocumentsByFilters('URS'))
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [])


    const isToday = (dateString: string) => {
        const date = new Date(dateString);
        const today = new Date();

        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const handleEditClick = (id) => {
        navigate(`/supplier-imported-urs/edit/${id}`)
    };


    const handleHideDialog = () => {
        setCreateDialogVisible(false);
    }

    const openCreateDialog = () => {
        setCreateDialogVisible(true);
    };


    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const columns: GridColDef[] =
        [
            {
                field: 'no',
                minWidth: 150,
                headerName: l10n.getString("urs-number", null, "_Numer URS"),
            },
            {
                field: 'version',
                width: 100,
                headerName: l10n.getString("document-version", null, "_Document version"),

            },
            {
                field: 'title',
                headerName: l10n.getString("document-title", null, "_Document title"),
                minWidth: 300,
                flex: 1
            },
            {
                field: 'createdByFullName',
                headerName: l10n.getString('meeting-minutes-author', null, 'Autor'),
                width: 300
            },
            {
                field: 'relatedCompanyName',
                headerName: l10n.getString('document-document-company-owner', null, '_document owner'),
                width: 300
            },
            {
                field: 'createDate',
                width: 150,
                headerName: l10n.getString('spec-created', null, 'Data utworzenia'),
                valueGetter: (_, row) => row.createDate ? new Date(row.createDate) : null,
                renderCell: (params) => {
                    if (!params.value) return "";
                    const formattedDate = params.value.toISOString().split('T')[0];
                    const isNew = isToday(params.row.createDate);

                    return (
                        <>
                            {formattedDate}
                            {isNew && <NewSmallBadge />}
                        </>
                    );
                },
            },
            {
                field: 'status',
                headerName: l10n.getString('meeting-minutes-status', null, 'Status'),
                width: 100,
                renderCell: (params) => {
                    return (
                        <div>
                            <DocumentStatusLabel status={params.row.status} />
                        </div>
                    )
                }
            },
            {
                field: 'actions', type: 'actions', width: 400,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button onClick={() => handleEditClick(params.row.id)} color="secondary" variant="outlined">
                                <Localized id="go-to">
                                    <span>Przejdz</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];


    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }
    return <>
        <TableContainer component={Paper}>
            <DataGrid
                style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                localeText={getCustomLocaleText()}
                rows={documents}
                columns={columns}
                slots={{ toolbar: QuickSearchToolbar }}
                onRowDoubleClick={({ row }) => handleEditClick(row.id)}
                initialState={{ sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] } }}
            />
            <FlexDiv>
                <AddFabButton onClick={openCreateDialog} />
            </FlexDiv>
        </TableContainer>
        {
            createDialogVisible &&
            <ImportSupplierUrsDialog onSucces={handleEditClick} onCancel={handleHideDialog} relatedDocument={null} />
        }
    </>
}

