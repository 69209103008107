import React, { useState } from 'react';
import { Box, Typography, Paper, Divider, Button, AppBar, Tabs, Tab, IconButton } from '@mui/material';
import { Print as PrintIcon } from '@mui/icons-material';
import { TreeView, TreeItem } from '@mui/lab';
import DescriptionView from './DescriptionView';
import ApprovalCardView from './ApprovalCardView';
import DocumentHistoryView from './DocumentHistoryView';
import TestsView from './TestsView';
import AddIcon from '@mui/icons-material/Add'
import DocumentDescriptionView from './DocumentDescriptionView';
import { ExpandMore, ChevronRight } from '@mui/icons-material';

const treeData = [
    { id: '1', label: 'Introduction' },
    { id: '2', label: 'Approval Card' },
    { id: '3', label: 'Document History' },
    {
        id: '4', label: 'Description',
        children: [
            { id: '4.1', label: 'Purpose' },
            { id: '4.2', label: 'Description' },
            { id: '4.3', label: 'Related Documents' },
            { id: '4.4', label: 'Abbreviations' },
            { id: '4.5', label: 'Standards' },
            { id: '4.6', label: 'Tools' }
        ]
    },
    { id: '5', label: 'Tests' }
];

const SupplierFSMockup: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedNode, setSelectedNode] = useState<string | null>(null);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleNodeSelect = (event: React.SyntheticEvent, nodeId: string) => {
        setSelectedNode(nodeId);
    };

    const renderTree = (nodes: any[]) => (
        nodes.map((node) => (
            <TreeItem key={node.id} nodeId={node.id} label={
                <Box display="flex" alignItems="center">
                    <Typography>{node.id}. {node.label}</Typography>
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                </Box>
            }>
                {node.children && renderTree(node.children)}
            </TreeItem>
        ))
    );

    const findNodeLabel = (id: string, nodes: any[]): string | null => {
        for (const node of nodes) {
            if (node.id === id) return node.label;
            if (node.children) {
                const foundLabel = findNodeLabel(id, node.children);
                if (foundLabel) return foundLabel;
            }
        }
        return null;
    };

    const renderView = () => {
        if (!selectedNode) {
            return <Typography variant="body1" color="textSecondary">Select an item from the structure to see details.</Typography>;
        }
        if (selectedNode.startsWith('1') || selectedNode.startsWith('4')) {
            return <DescriptionView title={findNodeLabel(selectedNode, treeData) || ''} number={selectedNode} />;
        }
        if (selectedNode === '2') return <ApprovalCardView />;
        if (selectedNode === '3') return <DocumentHistoryView />;
        if (selectedNode === '5') return <TestsView />;
        return null;
    };

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <Box display="flex">
                        <Paper elevation={3} sx={{ width: '20%', padding: 2 }}>
                            <Typography variant="h6">Document Structure</Typography>
                            <Divider sx={{ my: 2 }} />
                            <TreeView  onNodeSelect={handleNodeSelect} defaultCollapseIcon={<ExpandMore />}
                                defaultExpandIcon={<ChevronRight />}>
                                {renderTree(treeData)}
                            </TreeView>
                        </Paper>
                        <Paper elevation={3} sx={{ width: '80%', padding: 2, overflowY: 'auto' }}>
                            {renderView()}
                        </Paper>
                    </Box>
                );
            case 1:
                return <DocumentDescriptionView />;
            case 2:
                return <TestsView />;
            case 3:
                return <ApprovalCardView />;
            case 4:
                return <Typography variant="body1">Attachments Tab Content</Typography>;

            default:
                return null;
        }
    };

    return (
        <Box display="flex" flexDirection="column" sx={{ height: 'calc(100vh - 150px)' }}>
            <Box p={2}>
                <Typography variant="h5" gutterBottom fontWeight="bold">Functional specification</Typography>
                <Typography variant="body2" color="textSecondary">
                    Version: 1 | Created by: John Smith | Date: {new Date().toLocaleDateString()} | Structure: Cars
                </Typography>
                <Divider sx={{ mt: 2 }} />
            </Box>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Document Tabs">
                <Tab label="Content" />
                <Tab label="Document" />
                <Tab label="Test overview" />
                <Tab label="Team" />
                <Tab label="Attachments" />
            </Tabs>
            <Box flexGrow={1} overflow="hidden">
                {renderContent()}
            </Box>
            <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, p: 0.5 }}>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" startIcon={<PrintIcon />}>
                        Print
                    </Button>
                </Box>
            </AppBar>
        </Box>
    );
};

export default SupplierFSMockup;