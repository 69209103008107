import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkAPIType } from "../../app/appTypes";
import { postWithAuth } from "../../http";

export interface OfferSuppliers {
    id: number;
    name: string;
    isInternal: boolean;
    position: number;
    offerId: number;
}

export interface UrsRequirementComparison {
    id: string;
    numeration: string;
    name: string;
    criticality: string;
    notes: string[];
    comments: string[];
}

export interface UrsOfferComparisonResult {
    suppliers: OfferSuppliers[];
    requirements: UrsRequirementComparison[];
}

export interface CompareOffersRequest {
    ursId: number;
    offerIds: number[];
}

export const loadComparisionResults = createAsyncThunk<UrsOfferComparisonResult, CompareOffersRequest, AppThunkAPIType>
    ("urs-offer-comparator/compare", async (data, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: "api/urs-offer-comparator/compare",
            payload: data,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as UrsOfferComparisonResult
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })
