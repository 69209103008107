import { DocumentFilters, URSListFilters } from "./types";

export function filtersToQueryString(data: DocumentFilters): string {
    const params = new URLSearchParams();
    const statuses = data.statusOptions.filter(s => s.selected)
    const roles = data.myRoleOptions.filter(r => r.selected)

    if(data.date.from !== "all" && data.date.to !== "all") {
        params.append("dateFrom", new Date(data.date.from).toISOString());
        params.append("dateTo", new Date(data.date.to).toISOString());
    }

    if(statuses.length !== 0) {
        params.append("statuses", statuses.map(s => s.uniqueName).join(","));
    }

    if(roles.length !== 0) {
        params.append("roles", roles.map(r => r.uniqueName).join(","));
    }
    return params.toString()
}

export const defURSFilters: URSListFilters = {
    date: { from: "all", to: "all" },
    statusOptions: [
        {
            uniqueName: "N",
            name: "_Nowy",
            translationCode: "document-status-n",
            selected: false
        },
        {
            uniqueName: "I",
            name: "_Do zatwierdzenia",
            translationCode: "document-status-i",
            selected: false
        },
        {
            uniqueName: "A",
            name: "_Zatwierdzony",
            translationCode: "document-status-a",
            selected: false
        },
        {
            uniqueName: "F",
            name:"_Zakończony",
            translationCode: "document-status-f",
            selected: false
        },
        {
            uniqueName: "C",
            name: "_Korekta",
            translationCode: "document-status-c",
            selected: false
        },
        {
            uniqueName: "P",
            name: "_Opublikowany",
            translationCode: "document-status-p",
            selected: false
        },
        {
            uniqueName: "O",
            name: "_Opiniowanie",
            translationCode: "document-status-o",
            selected: false
        },
        {
            uniqueName: "D",
            name: "_Zaopiniowany",
            translationCode: "document-status-d",
            selected: false
        },
        {
            uniqueName: "E",
            name: "_Zaimportowany",
            translationCode: "document-status-e",
            selected: false
        },
        {
            uniqueName: "R",
            name: "_Odrzucony",
            translationCode: "document-status-r",
            selected: false
        }
    ],
    myRoleOptions: [
        {
            uniqueName: "IN",
            name: "_Jestem w zespole",
            translationCode: "filters-im-in-the-team",
            checkOther: true,
            selected: false
        },
        {
            uniqueName: "C",
            name: "_Autor",
            translationCode: "urs-role-creator",
            selected: false
        },
        {
            uniqueName: "E",
            name: "_Edytor",
            translationCode: "urs-role-editor",
            selected: false
        },
        {
            uniqueName: "R",
            name: "_Opiniujący",
            translationCode: "urs-role-reviever",
            selected: false
        },
        {
            uniqueName: "A",
            name: "_Zatwierdzający",
            translationCode: "urs-role-approver",
            selected: false
        },
        {
            uniqueName: "AR",
            name: "_Opiniujący + Zatwierdzający",
            translationCode: "urs-role-reviever-and-approver",
            selected: false
        },
        {
            uniqueName: "V",
            name: "_Obserwator",
            translationCode: "urs-role-viewer",
            selected: false
        },
        {
            uniqueName: "EXT_R",
            name: "_Zewnętrzny opiniujący",
            translationCode: "urs-role-external-reviever",
            selected: false
        }
    ]
}
