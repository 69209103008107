import React from 'react';
import { Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const testData = [
    { id: 1, name: 'Test 1', stage: 'IQ', plannedDate: '2024-02-01', executionDate: '2024-02-05', status: 'Completed', plannedBy: 'Jan Kowalski', executedBy: 'Anna Nowak', approvedBy: 'Marek Wiśniewski', verifiedBy: 'Piotr Zając' },
    { id: 2, name: 'Test 2', stage: 'OQ', plannedDate: '2024-02-03', executionDate: '2024-02-07', status: 'In Progress', plannedBy: 'Piotr Nowak', executedBy: 'Maria Kowalska', approvedBy: 'Tomasz Lis', verifiedBy: 'Barbara Wójcik' },
    { id: 3, name: 'Test 3', stage: 'PQ', plannedDate: '2024-02-04', executionDate: '2024-02-10', status: 'Pending', plannedBy: 'Adam Malinowski', executedBy: 'Natalia Wójcik', approvedBy: 'Krzysztof Nowak', verifiedBy: 'Joanna Lis' },
    { id: 4, name: 'Test 4', stage: 'DQ', plannedDate: '2024-02-05', executionDate: '', status: 'Planned', plannedBy: 'Robert Wiśniewski', executedBy: '', approvedBy: '', verifiedBy: '' },
    { id: 5, name: 'Test 5', stage: 'IQ', plannedDate: '2024-02-06', executionDate: '2024-02-12', status: 'Completed', plannedBy: 'Magdalena Lis', executedBy: 'Andrzej Kowalski', approvedBy: 'Zbigniew Malinowski', verifiedBy: 'Anna Zając' },
    { id: 6, name: 'Test 6', stage: 'OQ', plannedDate: '2024-02-07', executionDate: '', status: 'Planned', plannedBy: 'Monika Kowalczyk', executedBy: '', approvedBy: '', verifiedBy: '' },
    { id: 7, name: 'Test 7', stage: 'PQ', plannedDate: '2024-02-08', executionDate: '2024-02-15', status: 'Completed', plannedBy: 'Łukasz Wójcik', executedBy: 'Ewelina Malinowska', approvedBy: 'Marek Zając', verifiedBy: 'Karol Wiśniewski' },
    { id: 8, name: 'Test 8', stage: 'DQ', plannedDate: '2024-02-09', executionDate: '', status: 'Planned', plannedBy: 'Dariusz Lis', executedBy: '', approvedBy: '', verifiedBy: '' },
    { id: 9, name: 'Test 9', stage: 'IQ', plannedDate: '2024-02-10', executionDate: '2024-02-17', status: 'Completed', plannedBy: 'Agnieszka Nowak', executedBy: 'Paweł Malinowski', approvedBy: 'Tomasz Kowalczyk', verifiedBy: 'Ewa Wiśniewska' },
    { id: 10, name: 'Test 10', stage: 'OQ', plannedDate: '2024-02-11', executionDate: '', status: 'Planned', plannedBy: 'Kamil Zając', executedBy: '', approvedBy: '', verifiedBy: '' }
];

const columns = [
    { field: 'id', headerName: 'L.P.', width: 70 },
    { field: 'name', headerName: 'Nazwa', width: 150 },
    { field: 'stage', headerName: 'Etap', width: 100 },
    { field: 'plannedDate', headerName: 'Planowana Data', width: 130 },
    { field: 'executionDate', headerName: 'Data Wykonania', width: 130 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'plannedBy', headerName: 'Planował', width: 150 },
    { field: 'executedBy', headerName: 'Wykonał', width: 150 },
    { field: 'approvedBy', headerName: 'Zatwierdził', width: 150 },
    { field: 'verifiedBy', headerName: 'Zweryfikował', width: 150 },
];

const TestsView: React.FC = () => (
    <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" color="primary" gutterBottom>Testy</Typography>
        <Typography variant="body1" gutterBottom>Lista przeprowadzonych testów i ich wyniki.</Typography>
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid rows={testData} columns={columns} pageSizeOptions={[5, 10]} autoPageSize />
        </div>
    </Paper>
);

export default TestsView;
