import { ChangeEvent, useEffect, useState } from "react"
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import { login, selectUser } from './features/user/userSlice'
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { Localized, useLocalization } from "@fluent/react"
import { showError } from "./features/notifications/notificationsSlice";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCallback } from 'react';
import { selectSettings, setLocalePersistent, Locale } from './features/settings/settingsSlice';


const PREFIX = 'Login';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    logo: `${PREFIX}-logo`,
    text: `${PREFIX}-text`,
    backdrop: `${PREFIX}-backdrop`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '400px',
        margin: 'auto',
    },

    [`& .${classes.paper}`]: {
        width: "100%",
        paddingTop: 32,
        paddingBottom: 32,
    },

    [`& .${classes.logo}`]: {
        pointerEvents: 'none',
        height: 300,
    },

    [`& .${classes.text}`]: {
        width: '80%',
    },

    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    }
}));


export interface LoginAPIError {
    success: boolean
    token: null | string
    errors: string[]
}

export const LanguageDropdown = () => {
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector(selectSettings);

    const handleLangChange = useCallback((e: SelectChangeEvent) => {
        dispatch(setLocalePersistent(e.target.value as Locale));
    }, [dispatch])

    return <FormControl sx={{ width: '80%' }} >
        <InputLabel id="app-language-label">Language/Język</InputLabel>
        <Select
            labelId="app-language-label"
            id="app-language"
            value={locale}
            onChange={handleLangChange}
            label="Language/Język"
            fullWidth
        >
            <MenuItem value={'pl'}>polski</MenuItem>
            <MenuItem value={'en-US'}>english</MenuItem>
        </Select>
    </FormControl>
}

export const Login = () => {
    const dispatch = useAppDispatch()
    const { tokenState } = useAppSelector(selectUser)
    const { l10n } = useLocalization()
    const location = useLocation()
    const navigate = useNavigate()

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [mask, setMask] = useState<boolean>(false);

    const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            handleLoginClick()
        }
    };

    const handleLoginClick = async () => {
        if (username && password) {

            setMask(true)

            const resultAction = await dispatch(login({ username, password }))

            if (login.rejected.match(resultAction)) {
                const { payload } = resultAction
                if (payload) {
                    switch (payload.kind) {
                        case "http":
                            if (payload.body) {
                                const apiResponse = JSON.parse(payload.body) as LoginAPIError
                                const [error] = apiResponse.errors
                                dispatch(showError(error ?? "error-login-failed"))
                            } else {
                                dispatch(showError("error-login-failed"))
                            }
                            break
                        case "connection":
                            dispatch(showError("connection-issue"))
                            break
                    }
                } else {
                    dispatch(showError("error"))
                }
                setMask(false)
            }
        }
    }


    useEffect(() => {
        if (tokenState === 'valid') {
            const redirectDestination = location.state?.prevLocation ?? { pathname: "/" }
            navigate(redirectDestination)
        }
    }, [tokenState, location])

    switch (tokenState) {
        case "valid":
            return null
        default:
            return (
                <Root className={classes.root}>
                    <Paper variant="elevation" elevation={4} component="div" className={classes.paper}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    <Localized id="logging">
                                        <span>Logowanie</span>
                                    </Localized>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.text}
                                    onChange={onUsernameChange}
                                    label={l10n.getString("user", null, "Użytkownik")}
                                    type="email"
                                    autoComplete="email"
                                    aria-required="true"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="password"
                                    autoComplete="current-password"
                                    aria-required="true"
                                    className={classes.text}
                                    onChange={onPasswordChange}
                                    onKeyDown={handleEnter}
                                    label={l10n.getString("password", null, "Hasło")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LanguageDropdown />
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleLoginClick} color="primary" size="large" variant="contained">
                                    <Localized id="login">
                                        <span>Login</span>
                                    </Localized>
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginLeft: 30, marginRight: 20, marginTop: 20, marginBottom: 20 }} />
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 10, marginRight: 10 }}>
                            <Link to="/forgotpsw" style={{ alignContent: 'center' }}>
                                <Localized id="forgot-password-question">
                                    <span>Forgot password?</span>
                                </Localized>
                            </Link>
                        </Grid>
                        <Backdrop className={classes.backdrop} open={mask}>
                            <CircularProgress color="inherit"></CircularProgress>
                        </Backdrop>
                    </Paper>
                    <Box sx={{ marginTop: 1, }}>
                        <img className={classes.logo} src={process.env.PUBLIC_URL + '/logo.gif'} alt="Logo" />
                    </Box>
                </Root>
            );
    }
}

