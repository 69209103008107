import React, { useCallback, useState } from 'react';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Localized } from '@fluent/react';
import { DeletedDocumentsList } from '../../documents/deletedDocuments/DeletedDocumentsList';

type FormTabs = "deletedDocuments"


const ClientDocumentsOverview: React.FC = () => {
    const [tab, setTab] = useState<FormTabs>("deletedDocuments");

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])


    return (
        <TabContext value={tab}>
            <TabList onChange={handleTabChange}>
                <Tab
                    key={0}
                    value="deletedDocuments"
                    label={<Localized id="documents-deleted-overview">_Usunięte documenty</Localized>}
                />
            </TabList>
            <TabPanel value='deletedDocuments' sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <DeletedDocumentsList />
            </TabPanel>
        </TabContext>

    );
};

export default ClientDocumentsOverview;