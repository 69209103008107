import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';

interface EditableFieldProps {
    number?: string;
    initialValue: string;
    onSave: (newValue: string) => void;
}

const EditableField: React.FC<EditableFieldProps> = ({ number, initialValue, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [tempValue, setTempValue] = useState(initialValue);
    const [isHovered, setIsHovered] = useState(false);

    const handleEditClick = () => setIsEditing(true);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setTempValue(event.target.value);
    const handleApprove = () => {
        setIsEditing(false);
        onSave(tempValue);
    };
    const handleCancel = () => {
        setTempValue(initialValue);
        setIsEditing(false);
    };

    return (
        <Box display="flex" alignItems="center" gap={1} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {number && (
                <Typography variant="h5" color="primary">
                    {number}.
                </Typography>
            )}
            {isEditing ? (
                <>
                    <TextField value={tempValue} onChange={handleChange} autoFocus variant="standard" fullWidth />
                    <IconButton color="success" onClick={handleApprove}>
                        <CheckIcon />
                    </IconButton>
                    <IconButton color="error" onClick={handleCancel}>
                        <CloseIcon />
                    </IconButton>
                </>
            ) : (
                <Box display="flex" alignItems="center" gap={1} sx={{ cursor: 'pointer' }} onClick={handleEditClick}>
                    <Typography
                        variant="h5"
                        color="primary"
                        sx={{
                            transition: "0.2s",
                            ...(isHovered && { textDecoration: "underline", color: "secondary.main" })
                        }}
                    >
                        {tempValue}
                    </Typography>
                    {isHovered && (
                        <IconButton size="small" color="primary">
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default EditableField;
