import React, { useState } from 'react';
import { Box, Typography, Paper, Divider, Button, AppBar, Tabs, Tab, IconButton } from '@mui/material';
import { Print as PrintIcon } from '@mui/icons-material';
import { TreeView, TreeItem } from '@mui/lab';
import DescriptionView from './DescriptionView';
import ApprovalCardView from './ApprovalCardView';
import DocumentHistoryView from './DocumentHistoryView';
import TestsView from './TestsView';
import AddIcon from '@mui/icons-material/Add'
import DocumentDescriptionView from './DocumentDescriptionView';
import { ExpandMore, ChevronRight } from '@mui/icons-material';

const initialTreeData = [
    { id: 1, label: 'Introduction' },
    { id: 2, label: 'Approval Card' },
    { id: 3, label: 'Document History' },
    {
        id: 4, label: 'Description',
        children: [
            { id: 5, label: 'Purpose' },
            { id: 6, label: 'Description' },
            { id: 7, label: 'Related Documents' },
            { id: 8, label: 'Abbreviations' },
            { id: 9, label: 'Standards' },
            { id: 10, label: 'Tools' }
        ]
    },
    { id: 11, label: 'Tests' }
];

const ContentMockup: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedNode, setSelectedNode] = useState<string | null>(null);
    const [treeData, setTreeData] = useState(initialTreeData);
    const [nextId, setNextId] = useState(12);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleNodeSelect = (event: React.SyntheticEvent, nodeId: string) => {
        setSelectedNode(nodeId);
    };

    const handleAddNewItem = () => {
        const newId = nextId;
        const newItem = { id: newId, label: `New Item ${newId}` };
        setTreeData([...treeData, newItem]);
        setNextId(nextId + 1);
    };

    const handleAddChildItem = (parentId: number) => {
        const addChild = (nodes: any[]): any[] => {
            return nodes.map(node => {
                if (node.id === parentId) {
                    const newChildId = nextId;
                    const newChild = { id: newChildId, label: `New Item ${newChildId}` };
                    setNextId(nextId + 1);
                    return {
                        ...node,
                        children: node.children ? [...node.children, newChild] : [newChild]
                    };
                }
                if (node.children) {
                    return { ...node, children: addChild(node.children) };
                }
                return node;
            });
        };
        setTreeData(addChild(treeData));
    };

    const renderTree = (nodes: any[], parentNumeration = '') => (
        nodes.map((node, index) => {
            const numeration = parentNumeration ? `${parentNumeration}.${index + 1}` : `${index + 1}`;
            return (
                <TreeItem key={node.id} nodeId={node.id.toString()} label={
                    <Box display="flex" alignItems="center" sx={{ position: 'relative', '&:hover .add-icon': { opacity: 1 } }}>
                        <Typography>{numeration}. {node.label}</Typography>
                        <IconButton size="small" sx={{ marginLeft: 1, position: 'absolute', right: 0, opacity: 0, transition: 'opacity 0.3s' }} className="add-icon" onClick={() => handleAddChildItem(node.id)}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Box>
                }>
                    {node.children && renderTree(node.children, numeration)}
                </TreeItem>
            );
        })
    );

    const findNodeLabel = (id: string, nodes: any[]): string | null => {
    
        for (const node of nodes) {
            if (node.id.toString() == id) return node.label;
            if (node.children) {
                const foundLabel = findNodeLabel(id, node.children);
                if (foundLabel) return foundLabel;
            }
        }
        return null;
    };

    const renderView = () => {
        console.log(selectedNode);
        if (!selectedNode) {
            return <Typography variant="body1" color="textSecondary">Select an item from the structure to see details.</Typography>;
        }
        if (selectedNode === '2') return <ApprovalCardView />;
        if (selectedNode === '3') return <DocumentHistoryView />;
        if (selectedNode === '5') return <TestsView />;
        return <DescriptionView title={findNodeLabel(selectedNode, treeData) || ''} number={""} />;
    };

    const renderContent = () => {
        return (
            <Box display="flex">
                <Paper elevation={3} sx={{ width: '20%', padding: 2 }}>
                    <Typography variant="h6">Document Structure</Typography>
                    <Divider sx={{ my: 2 }} />
                    <TreeView onNodeSelect={handleNodeSelect} defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}>
                        {renderTree(treeData)}
                    </TreeView>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} startIcon={<AddIcon />} onClick={handleAddNewItem}>
                        Add New Item
                    </Button>
                </Paper>
                <Paper elevation={3} sx={{ width: '80%', padding: 2, overflowY: 'auto' }}>
                    {renderView()}
                </Paper>
            </Box>
        );
    };

    return (
        <Box display="flex" flexDirection="column" sx={{ height: 'calc(100vh - 150px)' }}>
            <Box flexGrow={1} overflow="hidden">
                {renderContent()}
            </Box>
        </Box>
    );
};

export default ContentMockup;