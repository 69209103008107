import { Localized } from "@fluent/react";
import React from "react";

interface SmallLabeledBadgeProps {
    label?: string;
}

const SmallLabeledBadge: React.FC<SmallLabeledBadgeProps> = ({ label = "" }) => {
    return (
        <span
            className="ml-1 px-1 py-0.5 rounded bg-green-200 text-green-800 font-semibold text-sm relative"
            style={{ top: '-5px', fontSize: '0.7rem' }}
        >
            <Localized id={label}>
                <span>{label}</span>
            </Localized>
        </span>
    );
};

export default SmallLabeledBadge;