import React from "react";

interface NewSmallBadgeProps {
    label?: string;
}

const NewSmallBadge: React.FC<NewSmallBadgeProps> = ({ label = "NEW" }) => {
    return (
        <span
            className="ml-1 px-1 py-0.5 rounded bg-green-200 text-green-800 font-semibold text-sm relative"
            style={{ top: '-5px', fontSize: '0.7rem' }}
        >
            {label}
        </span>
    );
};

export default NewSmallBadge;
