import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {AppDataState, AppId, AppThunkAPIType, unknownError} from "../../app/appTypes";
import {RootState} from "../../app/store";
import {getWithAuth} from "../../http";
import {logout} from "../user/userSlice";
import {createURS, deleteUrs, postURSAction, publishAction, selectSupplierOffer, selectSupplierOfferWihtNewVersion, updateURS, UrsHeader} from "./UrsSlice";

const adapter = createEntityAdapter<UrsHeader>({
    selectId: (urs) => urs.id,
})

export type UrsList = EntityState<UrsHeader> & { state: AppDataState, moduleId: AppId | undefined, filter: string }

const initialState: UrsList = adapter.getInitialState({
    state: { type: "empty" },
    moduleId: undefined,
    filter: ""
})

export const loadUrsList = createAsyncThunk<UrsHeader[], { moduleId: AppId, filter: string } , AppThunkAPIType>("urs/load", 
    async ({ moduleId, filter }, { dispatch, rejectWithValue, getState, requestId }) => {
        const state = getState().ursList.state
        const f = filter !== "" ? `&${filter}` : filter
        if (state.type === "loading" && state.requestId === requestId) {
            const result = await dispatch(getWithAuth({
                url: `api/Urs?moduleId=${moduleId}${f}`
            }))
            const { payload } = result 
            if (getWithAuth.fulfilled.match(result)) {
                return payload as UrsHeader[] 
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        }
        return []
})

export const ursListSlice = createSlice({
    name: "ursList",
    initialState,
    reducers: {
        clearUrsSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadUrsList.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadUrsList.pending, (state, action) => {
            state.state = {
                type: "loading",
                requestId: action.meta.requestId,
            }
        })
        builder.addCase(loadUrsList.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = {
                    type: "loaded"
                }
                state.moduleId = action.meta.arg.moduleId
                state.filter = action.meta.arg.filter
            }
        })
        builder.addCase(createURS.fulfilled, (state, action) => {
            const { ursRequirements, teamMembers, links, ...ursHeader } = action.payload
            adapter.upsertOne(state, ursHeader)
        })
        builder.addCase(updateURS.fulfilled, (state, action) => {
            const { ursRequirements, teamMembers, links, ...ursHeader } = action.payload
            adapter.upsertOne(state, ursHeader)
        })
        builder.addCase(deleteUrs.fulfilled, (state, action) => {           
            const  id  = action.payload           
            state = adapter.removeOne(state, id);
        })
        builder.addCase(postURSAction.fulfilled, (state, action) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
        builder.addCase(publishAction.fulfilled, (state, action) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
        builder.addCase(selectSupplierOfferWihtNewVersion.fulfilled, (state, action) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
            
        })
        builder.addCase(selectSupplierOffer.fulfilled, (state, action) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
        builder.addCase(logout.fulfilled, (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const selectUrs = (state: RootState): UrsList => state.ursList

export const 
    { selectAll: selectAllUrs
    , selectById: selectUrsById 
    , selectTotal: selectTotalUrs
    } = adapter.getSelectors<RootState>(selectUrs)

export default ursListSlice.reducer;

