import { Localized, useLocalization } from '@fluent/react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useDataGridCustomLocaleText } from '../../app/hooks'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup, Paper, TableContainer } from '@mui/material'
import { FlexDiv } from '../../app/Utils'
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { loadSupplierDeviceInstances, SupplierDeviceInstance } from './supplierDeviceInstanceApi'
import { unwrapResult } from '@reduxjs/toolkit'
import { LoadingContainer } from '../../app/LoadingContainer'
import { QuickSearchToolbar } from '../../app/QuickSearchToolbar'
import { CreateSupplierDeviceInstanceDialog } from './dialogs/CreateSupplierDeviceInstanceDialog'
import { getStructureName } from '../device/Structure'
import { ErrorContainer } from '../../app/ErrorContainer'
import { SupplierDeviceValidationStatusLabel } from './utils/SupplierDeviceValidationStatusLabel'
import { AddFabButton } from '../../app/components/AddFabButton'
import { If } from '../../app/If'
import { useLocalStorage } from '../../useLocalStorage'
import { DocumentFilters } from '../documents/utils/types'
import { filtersToQueryString } from '../documents/utils/helper'
import { DocumentFilterPanel } from '../documents/utils/DocumentFilterPanel'

const defaultFilters: DocumentFilters = {
    date: { from: "all", to: "all" },
    myRoleOptions: [],
    statusOptions: [
        {
            uniqueName: "VALIDATED",
            name: "_Zwalidowane",
            translationCode: "validation-status-validated",
            selected: false
        },
        {
            uniqueName: "INPROGRESS",
            name: "_W trakcie",
            translationCode: "validation-status-inprogress",
            selected: false
        },
        {
            uniqueName: "NOT_VALIDATED",
            name: "_Nie zwalidowane",
            translationCode: "validation-status-not-validated",
            selected: false
        }
    ]
}

export const SupplierDeviceInstancesList = () => {
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [devices, setDevices] = useState<SupplierDeviceInstance[]>([]);
    const localeText = useDataGridCustomLocaleText();
    const [createDialogVisible, setCreateDialogVisible] = useState(false);
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);

    const [filters, setFilters] = useLocalStorage<DocumentFilters>("SupplierDevicesListFilters", defaultFilters)
    const f = useMemo(() => filtersToQueryString(filters), [filters])

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadSupplierDeviceInstances({ filter: f }))
            .then(unwrapResult)
            .then(setDevices)
            .catch((error) => { console.log(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }, [])

    const handleGoToClick = (id) => {
        navigate(`/supplier-device/edit/${id}`)
    };

    const handleHideDialog = () => {
        setCreateDialogVisible(false);
    }

    const openCreateDialog = () => {
        setCreateDialogVisible(true);
    };

    const columns: GridColDef[] = [
        {
            field: 'serialNo',
            headerName: l10n.getString("devices-serial-number", null, "_Nr fabryczny"),
            minWidth: 200
        },
        {
            field: 'name',
            headerName: l10n.getString("devices-name", null, "_Nazwa"),
            flex: 1
        },
        {
            field: 'deviceTypeId',
            headerName: l10n.getString("structure", null, "_structure"),
            flex: 1,
            valueGetter: (_, row) => {
                return getStructureName(row.deviceTypeId)
            }
        },
        {
            field: 'validationStatus',
            headerName: l10n.getString("devices-validation-status", null, "_validation status"),
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="flex items-center justify-center h-full">
                        <SupplierDeviceValidationStatusLabel status={params.row.validationStatus} styled={false} />
                    </div>
                );
            }
        },
        {
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            onClick={() => handleGoToClick(params.row.id)}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="go-to">
                                <span>_goto</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    const reload = () => {
        setIsLoading(true)
        dispatch(loadSupplierDeviceInstances({ filter: f }))
            .then(unwrapResult)
            .then(setDevices)
            .catch((error) => { console.log(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    return <>
        <DocumentFilterPanel filters={filters} onFilterChange={setFilters} reload={reload}/>
        <If condition={!isLoading} otherwise={<LoadingContainer />}>
            <TableContainer component={Paper}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            style={{ minHeight: 100, height: 'calc(100vh - 250px)' }}
                            localeText={localeText}
                            rows={devices}
                            columns={columns}
                            slots={{ toolbar: QuickSearchToolbar }}
                            onRowDoubleClick={({ row }) => handleGoToClick(row.id)} />
                    </div>
                </div>
                <FlexDiv>
                    <AddFabButton onClick={openCreateDialog} />
                </FlexDiv>
            </TableContainer>
            {
                createDialogVisible &&
                <CreateSupplierDeviceInstanceDialog onSucces={handleGoToClick} onCancel={handleHideDialog} />
            }
        </If>
    </>
}
