import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, Box, AppBar, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ErrorContainer } from "../../app/ErrorContainer";
import { LoadingContainer } from "../../app/LoadingContainer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CompareOffersRequest, loadComparisionResults, UrsOfferComparisonResult } from "./UrsOfferComparatorApi";
import { unwrapResult } from "@reduxjs/toolkit";
import { NoteLabel } from "../ursOffer/NoteLabel";
import { RequirementNote } from "../ursOffer/ursOfferSlice";
import PrintIcon from '@mui/icons-material/Print';
import { selectSettings } from "../settings/settingsSlice";
import { Localized } from "@fluent/react";

export const UrsOfferComparator = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    const selectedOfferIds = location.state?.selectedOfferIds || [];
    const ursId = location.state?.ursId || 0;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [error, setError] = useState(null);
    const [compareResults, setCompareResults] = useState<UrsOfferComparisonResult | null>(null);
    const { locale } = useAppSelector(selectSettings);

    const [activeSuppliers, setActiveSuppliers] = useState<{ id: number; offerId: number; active: boolean, name: string }[]>([]);

    useEffect(() => {
        setIsLoading(true);
        const request: CompareOffersRequest = { ursId, offerIds: selectedOfferIds };

        dispatch(loadComparisionResults(request))
            .then(unwrapResult)
            .then((result) => {
                setCompareResults(result);
                setActiveSuppliers(result.suppliers.map(s => ({ id: s.id, offerId: s.offerId, active: true, name: s.name })));
            })
            .catch((error) => {
                console.error(error);
                setIsError(true);
                setError(error);
            })
            .finally(() => setIsLoading(false));
    }, [dispatch, ursId, selectedOfferIds]);

    if (isError) return <ErrorContainer error={error} />;
    if (isLoading) return <LoadingContainer />;
    if (!compareResults) return <Typography variant="h6">Brak wyników porównania</Typography>;

    const toggleSupplierVisibility = (supplierId: number, offerId: number) => {
        setActiveSuppliers((prev) =>
            prev.map(s =>
                s.id === supplierId && s.offerId === offerId ? { ...s, active: !s.active } : s
            )
        );
    };

    const handlePrint = async () => {
        const offerIdsParam = activeSuppliers.filter(s => s.active).map(x => `offerIds=${x.offerId}`).join("&");
        if (offerIdsParam) {
            const url = `${process.env.REACT_APP_REST_API}api/urs-offer-comparator/report/${ursId}?language=${locale}&${offerIdsParam}`;

            window.open(url, '_blank');
        }

    };



    return (
        <TableContainer component={Paper} sx={{ mt: 3, p: 2 }}>
            <Box sx={{ mb: 2, display: "flex", gap: 1, flexWrap: "wrap" }}>
                {activeSuppliers.map(supplier => (
                    <Chip
                        key={`${supplier.id}-${supplier.offerId}`}
                        label={`${supplier.name}`}
                        onClick={() => toggleSupplierVisibility(supplier.id, supplier.offerId)}
                        sx={{
                            backgroundColor: supplier.active ? "green" : "#ccc",
                            color: supplier.active ? "white" : "black",
                            fontWeight: "bold",
                            cursor: "pointer"
                        }}
                    />
                ))}
            </Box>

            <Typography variant="h6" gutterBottom>
                Porównanie ofert ({activeSuppliers.filter(s => s.active).length})
            </Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Nr</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Wymaganie</TableCell>
                        {compareResults.suppliers
                            .filter(supplier => activeSuppliers.some(vs => vs.id === supplier.id && vs.offerId === supplier.offerId && vs.active))
                            .map((supplier, index) => (
                                <TableCell
                                    key={`${supplier.id}-${supplier.offerId}`}
                                    colSpan={2}
                                    align="center"
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                                        borderLeft: index > 0 ? "2px solid #ddd" : "none",
                                    }}
                                >
                                    {supplier.name}
                                </TableCell>
                            ))}
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        {compareResults.suppliers
                            .filter(supplier => activeSuppliers.some(vs => vs.id === supplier.id && vs.offerId === supplier.offerId && vs.active))
                            .map((supplier, index) => (
                                <React.Fragment key={`${supplier.id}-${supplier.offerId}`}>
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                                            borderLeft: index > 0 ? "2px solid #ddd" : "none",
                                        }}
                                    >
                                        Ocena
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                                        }}
                                    >
                                        Komentarz
                                    </TableCell>
                                </React.Fragment>
                            ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {compareResults.requirements.map((req) => (
                        <TableRow key={req.id}>
                            <TableCell>{req.numeration}</TableCell>
                            <TableCell>{req.name}</TableCell>
                            {compareResults.suppliers
                                .filter(supplier => activeSuppliers.some(vs => vs.id === supplier.id && vs.offerId === supplier.offerId && vs.active))
                                .map((supplier, index) => (
                                    <React.Fragment key={`${supplier.id}-${supplier.offerId}-${req.id}`}>
                                        <TableCell
                                            sx={{
                                                borderLeft: index > 0 ? "2px solid #ddd" : "none",
                                                backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                                            }}
                                        >
                                            <NoteLabel note={req.notes[index] as RequirementNote | null} />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                                            }}
                                        >
                                            <Typography variant="caption">
                                                {req.comments[index] ?? "-"}
                                            </Typography>
                                        </TableCell>
                                    </React.Fragment>
                                ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, p: 0.5 }}>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="secondary" size="small"  startIcon={<PrintIcon />} onClick={handlePrint}>
                        <Localized id="print-compare">_Drukuj </Localized>
                    </Button>
                </Box>
            </AppBar>
        </TableContainer>

    );
};
