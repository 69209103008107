import { Localized } from "@fluent/react"
import { Box, Button, Divider, IconButton, Menu, MenuItem, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { AppId } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { If } from "../../app/If"
import AddIcon from '@mui/icons-material/Add'
import { URSRequirement, Urs, allURSRequirements, createURSRequirement, UrsRequirementType, UrsGroup, addUrsRequirementsFromCatalog, findSuccesor, findPredecessor } from "./UrsSlice"
import { URSRequirementRow } from "./URSRequirementRow"
import { selectAllURSComments, selectURSComments, URSComment } from "./URSCommentSlice"
import { store } from "../../app/store"
import { DeleteRequirementDialog } from "./dialogs/DeleteRequirementDialog"
import { EditRequirementDialog } from "./dialogs/EditRequirementDialog"
import { AttachementsDialog } from "./dialogs/AttachementsDialog"
import { CommentsDialog } from "./Comments"
import { RequirementDialogBrowser } from "../requirements/RequirementDialogBrowser"
import { selectRequirementById } from "../requirements/RequirementsDictSlice"
import { CreateMainGroupDialog } from "./dialogs/CreateMainGroupDialog"
import { CreateSubGroupDialog } from "./dialogs/CreateSubGroupDialog"
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { EditMainGroupDialog } from "./dialogs/EditMainGroupDialog"
import { DeleteGroupDialog } from "./dialogs/DeleteGroupDialog"
import { Group } from "./Group"
import { EditGroupDialog } from "./dialogs/EditGroupDialog"
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import { canUserComment, canUserEditComment, canUserResolveComment } from "./ursHelper"
import { selectLoggedUser } from "../user/userSlice"
import { AddRequirementDialog } from "./dialogs/AddRequirementDialog"
import { AddChildRequirementDialog } from "./dialogs/AddChildRequirmentDialog"

export interface RequirementsPanelProps {
    urs: Urs
    template: boolean
}
export const NoRequirements = () => {
    return <Typography gutterBottom component="div" color="textSecondary" variant="h5">
        <Localized id="urs-no-requirements">Brak wymagań.</Localized>
    </Typography>
}
type DialogState =
    | { tag: 'none' }
    | { tag: 'add', groupId: AppId }
    | { tag: 'add-from-catalog'
      , groupId: AppId
      , type: UrsRequirementType
      , selection: Set<AppId>
      }
    | { tag: 'add-child-requirment', parentRequirement: URSRequirement}
    | { tag: 'edit', id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType }
    | { tag: 'copy', srcId: AppId, group: string, typeId: AppId }
    | { tag: 'comments', requirement: URSRequirement }
    | { tag: 'attachements', requirement: URSRequirement }
    | { tag: 'delete', entity: URSRequirement }
    | { tag: 'add-main-group', id: AppId }
    | { tag: 'add-sub-group', id: AppId, groupId: string, groupName: string }
    | { tag: 'edit-main-group', groupId: AppId, groupCode: string, groupName: string }
    | { tag: 'delete-main-group', groupId: AppId }
    | { tag: 'edit-sub-group', groupId: AppId, groupCode: string, groupName: string }
    | { tag: 'delete-sub-group', groupId: AppId }

export const RequirementsPanel = ({ urs, template }: RequirementsPanelProps) => {
    const { id: ursId } = urs
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectLoggedUser)
    const canUpload = urs.links.save !== undefined
    const { deviceTypeId } = urs
    const [contextTypeId, setContextTypeId] = useState<AppId | null>(() => {
        if (urs.types.length > 0) {
            const [someItem] = urs.types
            return someItem.id
        } else {
            return null
        }
    })
    const [dialog, setDialog] = useState<DialogState>({ tag: 'none' })
    const comments = useAppSelector(state => {
        if (selectURSComments(state).ursId === ursId) {
            return selectAllURSComments(state)
        } else {
            return []
        }
    })

    // CALLBACKs
    const handleHideDialog = useCallback(() => setDialog({ tag: 'none' }), [])
    const handleRequirementCreation = useCallback((urs: Urs) => {
        if (contextTypeId === null) {
            setContextTypeId(urs.types[0]?.id)
        }
        setDialog({ tag: 'none' })
    }, [contextTypeId])
    const handleOKReqBrowserButtonClick = async (selection: Set<AppId>, sectionGuid: string) => {
        dispatch(addUrsRequirementsFromCatalog({
            ursId, 
            sectionGuid, 
            catalogIds: selection, }))
        if (selection.size > 0 && contextTypeId === null) {
            const someItem = selection.values().next()
            const firstRequirementId = someItem.value
            const someEntity = selectRequirementById(store.getState(), firstRequirementId)
            if (someEntity) {
                setContextTypeId(someEntity.typeId)
            }
        }
        handleHideDialog()
    }
    const userCanSendComment = useCallback(() => {
        return canUserComment(urs)
    }, [urs])
    const userCanApproveComment = useCallback(() => {
        return canUserResolveComment(urs)
    }, [urs])
    const userCanEditComment = useCallback((comment: URSComment) => {
        return canUserEditComment(user, urs, comment)
    }, [urs, user])

    // CALLBACKs
    
    const contextType = urs.types.find(x => x.id === contextTypeId)

    const handleMainGroupAdded : (data: Urs) => void = useCallback(data => {
        if (contextTypeId === null) {
            setContextTypeId(data.types[0]?.id)
        }
        setDialog({ tag: 'none' })
    }, [contextTypeId])

    function handleDeleteGroup(data: Urs): void {
        const currentType = data.types.find(x => x.id === contextTypeId)
        if (!currentType) {
            if (data.types.length > 0) {
                setContextTypeId(data.types[0].id)
            } else {
                setContextTypeId(null)
            }
        }
        setDialog({ tag: 'none' })
    }

    return <Box sx={{
        display: 'flex',
        height: 'calc(100vh - 340px)',
    }}>
        <If condition={urs.types.length > 0}>
            <RequirementsTabs urs={urs} typeId={contextTypeId ?? ""} setContextTypeId={setContextTypeId} />
        </If>
        <Stack sx={{
            paddingLeft: 4,
            width: '100%',
        }} direction="column" spacing={2}>
            {contextType === undefined ? <>
                <Stack direction="row" spacing={4}>
                    <NoRequirements />
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setDialog({ tag: 'add-main-group', id: ursId })}
                    >
                        <Localized id='urs-add-main-group'>Dodaj grupę główną</Localized>
                    </Button>
                </Stack>
            </> :
                <NonEmptyRequirementsPanel setContextTypeId={setContextTypeId} requirementType={contextType} template={template} urs={urs} setDialog={setDialog} />}
        </Stack>
        {dialog.tag === 'add' && <AddRequirementDialog 
                                        sectionId={dialog.groupId} 
                                        onCancel={handleHideDialog} 
                                        onSuccess={handleRequirementCreation} 
                                        />}
        {dialog.tag === 'add-child-requirment' && <AddChildRequirementDialog 
            parentRequirement={dialog.parentRequirement} 
            onCancel={handleHideDialog} 
            onSuccess={handleRequirementCreation} 
        />}
        {dialog.tag === 'edit' && <EditRequirementDialog 
                                        comments={comments}
                                        requirementGroup={dialog.group} 
                                        requirement={dialog.requirement}
                                        rootSection={dialog.rt}
                                        onSuccess={handleHideDialog}
                                        onCancel={handleHideDialog}
                                        canSendComments={userCanSendComment}
                                        canResolveComments={userCanApproveComment}
                                        canEditComment={userCanEditComment}
                                        isOfferReady={urs.supplierId !== null || urs.internalSupplierId !== null}
                                    />}
        {dialog.tag === 'delete' && <DeleteRequirementDialog typeId={dialog.entity.typeId ?? ''} group={dialog.entity.group ?? ''} entity={dialog.entity} onSuccess={handleHideDialog} onCancel={handleHideDialog} />}
        {dialog.tag === 'attachements' && <AttachementsDialog
            typeId={dialog.requirement.typeId ?? ''}
            group={dialog.requirement.group ?? ''}
            onClose={handleHideDialog}
            requirementId={dialog.requirement.id}
            requirement={dialog.requirement}
            canUpload={canUpload}
            canDelete={canUpload}
        />}
        {dialog.tag === 'comments' && <CommentsDialog
            comments={comments}
            ursId={ursId}
            requirement={dialog.requirement}
            onClose={handleHideDialog}
            isCommentEnabled={userCanSendComment}
            isResolvingEnabled={userCanApproveComment}
            isEditEnabled={userCanEditComment}
        />}
        {dialog.tag === 'add-from-catalog' && <RequirementDialogBrowser
            cancel={handleHideDialog}
            performAction={(selection) => handleOKReqBrowserButtonClick(selection, dialog.groupId)}
            deviceTypeId={deviceTypeId}
            disabled={dialog.selection}
            lang={urs.language ?? 'PL'}
            defaultTypeId={dialog.type && dialog.type.sysSectionId ? dialog.type.sysSectionId.toString() : undefined}
        />}
        {dialog.tag === 'add-main-group' && <CreateMainGroupDialog ursId={dialog.id} onCancel={handleHideDialog} onSucces={handleMainGroupAdded} />}
        {dialog.tag === 'add-sub-group' && <CreateSubGroupDialog ursId={dialog.id} parentGroupId={dialog.groupId} parentGroupName={dialog.groupName} onCancel={handleHideDialog} onSucces={handleHideDialog} />}
        {dialog.tag === 'edit-main-group' && <EditMainGroupDialog groupId={dialog.groupId} name={dialog.groupName} code={dialog.groupCode} onCancel={handleHideDialog} onSucces={handleMainGroupAdded} />}
        {dialog.tag === 'edit-sub-group' && <EditGroupDialog groupId={dialog.groupId} name={dialog.groupName} code={dialog.groupCode} onCancel={handleHideDialog} onSucces={handleHideDialog} />}
        {dialog.tag === 'delete-main-group' && <DeleteGroupDialog id={dialog.groupId} onCancel={handleHideDialog} onSuccess={handleDeleteGroup} />}
    </Box>
}

interface NonEmptyRequirementsPanelProps extends RequirementsPanelProps {
    setDialog: (dialog: DialogState) => void
    requirementType: UrsRequirementType
    setContextTypeId: Dispatch<SetStateAction<AppId | null>>
}
const NonEmptyRequirementsPanel = ({ urs, setDialog, template, requirementType, setContextTypeId }: NonEmptyRequirementsPanelProps) => {
    const { id: ursId } = urs

    const dispatch = useAppDispatch()

    const tableRef = useRef<null | HTMLDivElement>(null)

    const comments = useAppSelector(state => {
        if (selectURSComments(state).ursId === ursId) {
            return selectAllURSComments(state)
        } else {
            return []
        }
    })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const groupMenuOpen = Boolean(anchorEl);

    const showSupplierComment = urs.supplierId != null || urs.internalSupplierId != null 
    const editable = urs.links.save !== undefined
    const addChildRequirementActive = urs.links.addChildRequirement !== undefined

    useLayoutEffect(() => {
        if (tableRef.current) {
            tableRef.current.scroll({ top: 0, behavior: 'auto' })
        }
    }, [requirementType])

    // CALLBACKs
    const handleAddChildRequirmentClick = useCallback(( parentRequirement: URSRequirement) =>
        setDialog({ tag: 'add-child-requirment', parentRequirement }), [])

    const handleEditRequirementClick = useCallback((id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType) =>
        setDialog({ tag: 'edit', id, group, requirement, rt }), [])
    const handleCopyRequirementClick = useCallback(async (id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType) => {
        let src: URSRequirement | undefined
        for (const req of allURSRequirements(urs)) {
            if (req.id === id) {
            src = req
            break
            }
        }
        if (src) {
            const newUrs = await dispatch(createURSRequirement({ ...src, id: '' })).unwrap()
            setDialog({ tag: 'edit', id: newUrs.id, group, requirement, rt })
        }
    }, [urs])
    const handleDeleteRequirementClick = useCallback((entity: URSRequirement) =>
        setDialog({ tag: 'delete', entity }), [])
    const handleCommentsClick = useCallback((requirement: URSRequirement) =>
        setDialog({ tag: 'comments', requirement }), [])
    const handleAttachementsClick = useCallback((requirement: URSRequirement) =>
        setDialog({ tag: 'attachements', requirement }), [])
    const handleAddReqFromBrowserButtonClick = useCallback((groupId: AppId, rt: UrsRequirementType) => {
        const allCopied: Set<AppId> = new Set()
        for (const r of allURSRequirements(urs)) {
            if (r.requirementId !== null) {
                allCopied.add(r.requirementId.toString())
            }
        }
        setDialog({ tag: 'add-from-catalog', groupId, type: rt, selection: allCopied })
    }, [urs])
    const handleAddFromCatalogWithoutGroup = useCallback(() => {
        const allCopied: Set<AppId> = new Set()
        for (const r of allURSRequirements(urs)) {
            if (r.requirementId !== null) {
            allCopied.add(r.requirementId.toString())
            }
        }
        setDialog({ tag: 'add-from-catalog', groupId: requirementType.id, type: requirementType, selection: allCopied })
        setAnchorEl(null)
    }, [requirementType])
    // CALLBACKs

    const [hash, setHash] = useState<string | null>(null)

    useEffect(() => {
        if (hash !== null) {
            window.location.href = `#${hash}`
        }
    }, [hash])

    const handleGoToNextRequirementWithUnresolvedComments = () => {
        if (hash === null) {
            const allReqs = Array.from(allURSRequirements(urs))
            const req = allReqs.find(r => comments.some(c => c.ursRequirementId === r.id && c.status === 'N'))
            if (req) {
                setHash(req.guid)
            }
        } else {
            // find requirement
            const result = findSuccesor(urs, hash, r => {
                return comments.some(c => c.ursRequirementId === r.id && c.status === 'N' && c.parentCommentId === null)
            })
            if (result) {
                const [req, ty] = result
                if (ty.id !== requirementType.id) {
                    setContextTypeId(ty.id)
                }
                setHash(req.guid)
            }
        }
    }

    const handleGoToPreviousRequirementWithUnresolvedComments = () => {
        if (hash === null) {
            const req = Array.from(allURSRequirements(urs)).find(r => comments.some(c => c.ursRequirementId === r.id && c.status === 'N'))
            if (req) {
                setHash(req.guid)
            }
        } else {
            const result = findPredecessor(urs, hash, r => {
                return comments.some(c => c.ursRequirementId === r.id && c.status === 'N' && c.parentCommentId === null)
            })
            if (result) {
                const [req, ty] = result
                if (ty.id !== requirementType.id) {
                    setContextTypeId(ty.id)
                }
                setHash(req.guid)
            }
        }
    }

    const mask = useMemo(() => {
        if (showSupplierComment) {
            return <>
                <TableCell></TableCell>
                <TableCell>Loading data...</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </>
        } else {
            return <>
                <TableCell></TableCell>
                <TableCell>Loading data...</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </>
        }
    }, [])

    return <>
        <Typography variant="subtitle2">
            <Stack direction="row" justifyContent="space-between">
                <Stack direction='row' alignItems='center' spacing={2}>
                    <span>{`${requirementType.numeration}. ${requirementType.code} ${requirementType.name}`}</span>
                    {editable  && <IconButton sx={{ height: 40 }} onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>}
                    <Menu anchorEl={anchorEl} open={groupMenuOpen} onClose={() => setAnchorEl(null)}>
                        <MenuItem key='edit' onClick={() => {
                            setDialog({ 
                                tag: 'edit-main-group', 
                                groupId: requirementType.id, 
                                groupCode: requirementType.code,
                                groupName: requirementType.name
                            })
                            setAnchorEl(null)
                        }}><Localized id='edit'>Edytuj</Localized></MenuItem>
                        <MenuItem key='delete' disabled={requirementType.groups.length > 0} onClick={() => {
                            setDialog({ tag: 'delete-main-group', groupId: requirementType.id })
                            setAnchorEl(null)
                        }}><Localized id='delete'>Usuń</Localized></MenuItem>
                        <Divider />
                        <MenuItem key='add-main-group' onClick={() => {
                            setDialog({ tag: 'add-main-group', id: ursId })
                            setAnchorEl(null)
                        }}>
                            <Localized id='urs-add-main-group'>Dodaj grupę główną</Localized>
                        </MenuItem>
                        <MenuItem key='add-main-group' onClick={() => {
                            setDialog({ tag: 'add-sub-group', id: ursId, groupId: requirementType.id, groupName: requirementType.name })
                            setAnchorEl(null)
                        }}>
                            <Localized id='urs-add-sub-group'>Dodaj grupę</Localized>
                        </MenuItem>
                        <MenuItem disabled={requirementType.groups.length > 0} key='add-from-catalog' onClick={handleAddFromCatalogWithoutGroup}>
                            <Localized id='urs-add-from-catalog'>Katalog</Localized>
                        </MenuItem>
                        <MenuItem disabled={requirementType.groups.length > 0} key='add' onClick={() => {
                            setDialog({ tag: 'add', groupId: requirementType.id })
                            setAnchorEl(null)
                        }}>
                            <Localized id='urs-add'>Dodaj wymaganie</Localized>
                        </MenuItem>
                    </Menu>
                    <div>
                        <IconButton onClick={handleGoToPreviousRequirementWithUnresolvedComments}><SkipPreviousIcon /></IconButton>
                        <IconButton onClick={handleGoToNextRequirementWithUnresolvedComments}><SkipNextIcon /></IconButton>
                    </div>
                </Stack>
            </Stack>
        </Typography>
        <TableContainer ref={tableRef} sx={{
            maxHeight: 'calc(100vh - 380px)',
        }} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ minWidth: 50, width: 80, maxWidth: 80}}>
                            <Typography component="span" variant="subtitle2" color="textSecondary">
                                <Localized id="urs-item-lp">Lp</Localized>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography component="span" variant="subtitle2" color="textSecondary">
                                <Localized id="urs-item-name">Nazwa</Localized>
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                            <Typography component="span" variant="subtitle2" color="textSecondary">
                                <Localized id="urs-item-criticality">Krytyczność</Localized>
                            </Typography>
                        </TableCell>
                        <If condition={showSupplierComment}>
                            <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                <Typography component="span" variant="subtitle2" color="textSecondary">
                                    <Localized id="urs-item-note">Ocena</Localized>
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography component="span" variant="subtitle2" color="textSecondary">
                                    <Localized id="urs-item-comment">Komentarz</Localized>
                                </Typography>
                            </TableCell>
                        </If>
                        <TableCell sx={{ minWidth: 80, width: 80, maxWidth: 80, justifyContent: 'flex-end' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                {requirementType.requirements.map(requirement => 
                        <>
                            <URSRequirementRow
                                key={requirement.id}
                                id={requirement.id}
                                onAddChildRequirmentClick={handleAddChildRequirmentClick}
                                onEditClick={handleEditRequirementClick}
                                onCopyClick={handleCopyRequirementClick}
                                onDeleteClick={handleDeleteRequirementClick}
                                canEdit={editable}
                                canAddChildRequirement={addChildRequirementActive}
                                canDelete={editable}
                                showSupplierComment={showSupplierComment}
                                onCommentsClick={handleCommentsClick}
                                onAttachementsClick={handleAttachementsClick}
                                template={template}
                                requirement={requirement}
                                group={null}
                                rt={requirementType}
                                tableRef={tableRef}
                                mask={mask}
                            />
                            {requirement.childRequirements.map(childReq=>
                                <URSRequirementRow
                                key={childReq.id}
                                id={childReq.id}
                                onAddChildRequirmentClick={handleAddChildRequirmentClick}
                                onEditClick={handleEditRequirementClick}
                                onCopyClick={handleCopyRequirementClick}
                                onDeleteClick={handleDeleteRequirementClick}
                                canEdit={editable}
                                canAddChildRequirement={addChildRequirementActive}
                                canDelete={editable}
                                showSupplierComment={showSupplierComment}
                                onCommentsClick={handleCommentsClick}
                                onAttachementsClick={handleAttachementsClick}
                                template={template}
                                requirement={childReq}
                                group={null}                           
                                tableRef={tableRef}
                                mask={mask}
                                rt={requirementType} />
                            )}
                        </>
                    )}
                    {requirementType.groups.map(group => <>
                    <Group 
                        group={group} 
                        handleAddRequirement={(groupId) => setDialog({ tag: 'add', groupId })}
                        handleAddFromCatalog={() => handleAddReqFromBrowserButtonClick(group.id, requirementType)}
                        handleEditGroup={group => { setDialog({ tag: 'edit-sub-group', groupId: group.id, groupName: group.name, groupCode: '' }) }}
                        handleDeleteGroup={group => { setDialog({ tag: 'delete-main-group', groupId: group.id }) }}
                        hasAnyRequirement={group.requirements.length > 0}
                        canEdit={editable}
                        showSupplierComments={showSupplierComment}
                    />
                    {group.requirements.map(requirement =>
                        <>
                            <URSRequirementRow
                                key={requirement.id}
                                id={requirement.id}
                                onAddChildRequirmentClick={handleAddChildRequirmentClick}
                                onEditClick={handleEditRequirementClick}
                                onCopyClick={handleCopyRequirementClick}
                                onDeleteClick={handleDeleteRequirementClick}
                                canEdit={editable}
                                canDelete={editable}
                                canAddChildRequirement={addChildRequirementActive}
                                showSupplierComment={showSupplierComment}
                                onCommentsClick={handleCommentsClick}
                                onAttachementsClick={handleAttachementsClick}
                                template={template}
                                requirement={requirement}
                                group={{ ...group, typeDescription: `${requirementType.code} ${requirementType.name}` }}
                                rt={requirementType}
                                tableRef={tableRef}
                                mask={mask}
                            />
                            {requirement.childRequirements.map(childReq=>
                                <URSRequirementRow
                                    key={childReq.id}
                                    id={childReq.id}
                                    onAddChildRequirmentClick={handleAddChildRequirmentClick}
                                    onEditClick={handleEditRequirementClick}
                                    onCopyClick={handleCopyRequirementClick}
                                    onDeleteClick={handleDeleteRequirementClick}
                                    canEdit={editable}
                                    canAddChildRequirement={addChildRequirementActive}
                                    canDelete={editable}
                                    showSupplierComment={showSupplierComment}
                                    onCommentsClick={handleCommentsClick}
                                    onAttachementsClick={handleAttachementsClick}
                                    template={template}
                                    requirement={childReq}
                                    group={{ ...group, typeDescription: `${requirementType.code} ${requirementType.name}` }}
                                    rt={requirementType} 
                                    tableRef={tableRef}
                                    mask={mask}
                                />
                            )}
                       </>
                    )}
                    </>)}

                </TableBody>
            </Table>
        </TableContainer>
    </>
}

interface RequirementsTabsProps {
    urs : Urs
    typeId: AppId
    setContextTypeId: (typeId: AppId) => void
}
const RequirementsTabs = ({ urs, typeId, setContextTypeId }: RequirementsTabsProps) => {
    const allRequirementTypes = urs.types
    const handleReqsTabChange = useCallback((_, newReqTypeId) => {
        setContextTypeId(newReqTypeId)
    }, [])
    return <Tabs
        orientation="vertical"
        variant="standard"
        value={typeId}
        onChange={handleReqsTabChange}
        sx={{
            borderRight: 1,
            borderColor: 'divider',
        }}
    >
        {allRequirementTypes.map(data => <Tab
            key={data.id}
            label={data.code || data.name}
            value={data.id}
        />)}
    </Tabs>
}

