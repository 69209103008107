import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useAppDispatch } from "../../../app/hooks"
import { Localized } from "@fluent/react"
import { useState } from "react"
import { deleteSpec } from "../specSlice"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { DeleteButton } from "../../../app/components/DeleteButton"
import { CancelButton } from "../../../app/components/CancelButton"

export interface DeleteSpecDialogProps {
    specId: number
    onDelete: (specId: number) => void
    onCancel: () => void,
}

export const DeleteSpecDialog = ({ specId, onDelete, onCancel }: DeleteSpecDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState("")

    const handleConfirmDeleteClick = async () => {
        setSaving(true)
        if (specId) {
            try {
                const result = await dispatch(deleteSpec(
                    specId
                ))

                if (deleteSpec.fulfilled.match(result)) {
                    dispatch(showSuccess("deleted"))
                    onDelete(specId)
                }
                else {
                    let errorMsg = "error-cannot-delete-used-object"
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }

        setSaving(false)
    }

    return <>
        <Dialog open={true} maxWidth='sm'>
            <DialogTitle>
                <Localized id="confirm-delete">
                    <span>Czy na pewno chcesz usunąć?</span>
                </Localized>
            </DialogTitle>
            <DialogContent>
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <DeleteButton loading={saving} onClick={handleConfirmDeleteClick} />
                <CancelButton loading={saving} onClick={onCancel} />
            </DialogActions>
        </Dialog>
    </>
}
