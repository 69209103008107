import { Alert, Box, Button, ButtonGroup, debounce, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, SxProps, TextField, TextFieldProps, Theme, Typography } from "@mui/material"
import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { useAppDispatch } from "../../../app/hooks"
import { ChangeEvent, useCallback, useState, DragEvent, useRef, ReactNode } from "react"
import { showSuccess } from "../../notifications/notificationsSlice"
import { DeviceTypeCombobox } from "../../device/DeviceTypeCombobox"
import { APIError, AppId } from "../../../app/appTypes"
import { documentExists, DocumentHeader } from "../documentsApi"
import { If } from "../../../app/If"
import { ImportButton } from "../../../app/components/ImportButton"
import { CancelButton } from "../../../app/components/CancelButton"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { importUrsData, imporUrsHeader, IUrsBody } from "./ursImport"
import { ServerErrorMsg } from "../../../app/ServerErrorMsg"
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingOverlay } from "./LoadingOverlay"
import { Urs } from "../../urs/UrsSlice"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface ImportSupploerUrsDialogProps {
    relatedDocument: Urs | null
    onSucces: (document: DocumentHeader) => void
    onCancel: () => void
}

export const ImportSupplierUrsDialog = (props: ImportSupploerUrsDialogProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const relatedDocument = props.relatedDocument
    const [validationErrors, setValidationErrors] = useState(false)
    const [documentNumber, setDocumentNumber] = useState("");
    const [title, setTitle] = useState(relatedDocument ? relatedDocument.title : "");
    const [structureId, setDeviceTypeId] = useState<AppId>(relatedDocument ? relatedDocument.deviceTypeId : "");
    const [relatedCompanyName, setRelatedCompanyName] = useState(relatedDocument ? relatedDocument.relatedCompanyName : "");
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)
    const dropboxRef = useRef<HTMLDivElement | null>(null)
    const [uploading, setUploading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<FileList | never[] | null>(null)
    const [metadata, setMetadata] = useState<{ key: string; value: string }[]>([]);
    const [error, setError] = useState<APIError | null>(null)
    const anyFiles: boolean = selectedFiles !== null && selectedFiles.length > 0 ? true : false

    const titleError = validationErrors && title === ""
    const relatedCompanyNameError = validationErrors && relatedCompanyName === ""
    const isEditable = relatedDocument === null

    const handleDocumentNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDocumentNumber(e.target.value);
    };

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };
    const handleRelatedCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRelatedCompanyName(e.target.value);
    };

    const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        const dt = e.dataTransfer
        if (dt) {
            setSelectedFiles(dt.files)
        }
    }, [])
    const handleDeviceTypeIdChange = useCallback(structureId => setDeviceTypeId(structureId), [setDeviceTypeId])

    const handleUploadClick = async () => {
        setValidationErrors(true)

        if (selectedFiles && selectedFiles.length > 0 && structureId) {
            setUploading(true)
            let files: File[] = []
            for (const file of selectedFiles) {
                files.push(file)
            }
            const fd = new FormData()
            files.forEach(file => {
                fd.append("file", file)
            })
            fd.append("number", documentNumber)
            fd.append("title", title)
            fd.append("structureId", structureId)
            fd.append("description", title)
            fd.append("language", "EN")
            fd.append("relatedCompanyName", relatedCompanyName)
            fd.append("relatedDocumentId", relatedDocument ? relatedDocument.id : "")

            try {
                const response = await fetch(`${process.env.REACT_APP_IMPORT_API}import`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Authorization': `bearer ${process.env.REACT_APP_IMPORT_SECRET}`,
                    },
                    body: fd,
                })
                if (formRef) {
                    formRef.current?.reset()
                }
                setSelectedFiles(null)
                const result = await response.json() as IUrsBody
                console.log(result)

                const newUrs = await dispatch(imporUrsHeader({
                    description: title,
                    structureId,
                    title,
                    ursNumber: documentNumber,
                    language: 'EN',
                    relatedCompanyName,
                    relatedDocumentId: relatedDocument ? relatedDocument.id : ""
                })).unwrap()

                await dispatch(importUrsData({
                    id: newUrs.id,
                    body: result,
                })).unwrap()

                navigate(`/supplier-imported-urs/edit/${newUrs.id}`, { replace: true })

            } catch (error) {
                console.log(error)
                setError(error as APIError)
            } finally {
                setUploading(false)
            }
        }
    }

    const handleRemoveFile = (index: number) => {
        setSelectedFiles(null)
        if (formRef) {
            formRef.current?.reset()
        }
    };

    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [fileInputRef])

    const handleSelectedFilesChange = useCallback(() => {
        if (fileInputRef) {
            setSelectedFiles(fileInputRef.current?.files ?? null)
        }
    }, [fileInputRef, setSelectedFiles])

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle><Localized id='Import URS'>Import URS</Localized></DialogTitle>
        <DialogContent>
            <ServerErrorMsg err={error} />
            <Grid container spacing={2} sx={{ padding: 2 }}>
                <If condition={relatedDocument === null}>
                    <Grid item xs={12}>
                        <DocNoTextField
                            fullWidth={true}
                            required={true}
                            value={documentNumber}
                            onChange={handleDocumentNumberChange}
                            label={<Localized id="">Numer</Localized>}
                        />
                    </Grid>
                </If>
                <If condition={relatedDocument !== null}>
                    <Grid item xs={12}>
                        <Alert severity="info">
                            The imported document will be added as the next version of document <strong>{relatedDocument?.ursNo}</strong>.
                        </Alert>
                    </Grid>
                </If>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={titleError}
                        helperText={titleError && <RequiredError />}
                        multiline={true}
                        inputProps={{ maxLength: 200 }}
                        value={title}
                        onChange={handleTitleChange}
                        label={<Localized id="">Title</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={relatedCompanyNameError}
                        helperText={relatedCompanyNameError && <RequiredError />}
                        multiline={true}
                        disabled={!isEditable}
                        value={relatedCompanyName}
                        onChange={handleRelatedCompanyNameChange}
                        label={<Localized id="document-document-company-owner">_Document owner</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <DeviceTypeCombobox
                        onlyLeafs={true}
                        fullWidth
                        required
                        disabled={!isEditable}
                        error={structureId === ''}
                        helperText={structureId === '' && <RequiredError />}
                        value={structureId}
                        onChange={handleDeviceTypeIdChange}
                        label={<Localized id="structure">Struktura</Localized>}
                    />
                </Grid>
                <Box sx={{
                    width: "100%",
                    minHeight: 100,
                    border: "2px dashed grey",
                    borderRadius: 8,
                    marginTop: 2,
                    padding: 2,
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "background-color 0.2s, border-color 0.2s",
                    "&:hover": {
                        backgroundColor: "#f5f5f5",
                        borderColor: "primary.main",
                    },
                    "&:active": {
                        backgroundColor: "#e0e0e0",
                    },
                }}>
                    <form ref={formRef}>
                        <input
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            type="file"
                            multiple={false}
                            onChange={handleSelectedFilesChange}
                            accept=".pdf,.docx"
                        />
                    </form>
                    <LoadingOverlay isOpen={uploading} />
                    <Box ref={dropboxRef} component="div" onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDrop={handleDrop}>
                        <If condition={!anyFiles}>
                            <UploadFileIcon fontSize="large" color="action" />
                            <Typography variant="body1" color="GrayText" gutterBottom>
                                <Localized id="">Drag and drop to upload</Localized>
                            </Typography>
                            <Typography variant="body1" color="GrayText" gutterBottom>
                                <Localized id="">or</Localized>
                            </Typography>
                            <Button size="small" variant="contained" onClick={handleSelectFilesClick}>
                                <Localized id="select-file">Browse file</Localized>
                            </Button>
                        </If>
                        <If condition={anyFiles}>
                            <FileList files={selectedFiles} onRemoveFile={handleRemoveFile} />
                        </If>
                    </Box>
                </Box>
            </Grid>
        </DialogContent>
        <DialogActions>
            <ImportButton loading={uploading} onClick={handleUploadClick} />
            <CancelButton loading={uploading} onClick={props.onCancel} />
        </DialogActions>
    </Dialog>
}

const FileList = ({ files, onRemoveFile }: { files: FileList | never[] | null, onRemoveFile: (index: number) => void }) => {
    if (files === null) {
        return <span>No files</span>
    }

    let idx: number[] = []
    for (let i = 0; i < files.length; i++) {
        idx.push(i)
    }

    return <List>{idx.map(j => {
        const file = files[j]
        const url = URL.createObjectURL(file)
        return <ListItem>
            <ListItemAvatar>
                <PictureAsPdfIcon />
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`size: ${file.size}B`}></ListItemText>
            <IconButton edge="end" aria-label="delete">
                <DeleteIcon color="primary" onClick={() => onRemoveFile(j)} />
            </IconButton>
        </ListItem>
    })}</List>
}

interface Result {
    ok: boolean
    docId: string
}

interface DocNoTextFieldProps {
    value: string
    onChange: (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    sx?: SxProps<Theme>;
    label: ReactNode
    fullWidth?: boolean
    required?: boolean
}
function DocNoTextField(props: DocNoTextFieldProps): JSX.Element {
    const { sx, value, onChange, required, fullWidth, label } = props
    const dispatch = useAppDispatch()

    const [status, setStatus] = useState<undefined | 'checking' | 'ok' | 'exists'>(undefined)

    const check = useCallback(debounce((pattern) => {
        setStatus('checking')
        dispatch(documentExists(pattern))
            .then(unwrapResult)
            .then(({ exists }) => {
                if (exists) {
                    setStatus('exists')
                } else {
                    setStatus('ok')
                }
            })
            .catch((err) => {
                console.error(err)
                setStatus(undefined)
            })
    }, 800), [])

    const error: boolean = value === '' || status === 'exists'
    let helperText: ReactNode = <></>
    switch (status) {
        case 'checking':
            helperText = 'Checking...'
            break
        case 'exists':
            helperText = 'Document with such number exists!'
            break
        case 'ok':
            helperText = 'OK'
            break
        default:
            helperText = 'Please provide document number.'
            break;
    }

    return <TextField
        value={value}
        onChange={e => {
            onChange(e)
            check(e.target.value)
        }}
        error={error}
        helperText={helperText}
        required={required}
        fullWidth={fullWidth}
        label={label}
    />
}
