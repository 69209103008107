import { Button, Checkbox, Divider, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { useState } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Localized } from "@fluent/react";
import { FilterOptions } from "./types";

interface StandardFilterMenuProps {
    label: string;
    multiple?: boolean;
    options: FilterOptions[];
    onUpdate: (label:string) => void;
}

export const StandardFilterMenu = ({ label, multiple, options, onUpdate }:StandardFilterMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    
    return (
        <>
            <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget) } variant="text" startIcon={<FilterListIcon/>}>
                {label}
            </Button>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null) }>
                <MenuList>
                    {options.filter(f => f.checkOther).map(option => 
                        <MenuItem onClick={() => onUpdate(option.uniqueName)}>
                            {multiple ? <Checkbox checked={option.selected}/> : null }
                            <ListItemText>
                                {option.translationCode ? <Localized id={option.translationCode}>{option.name}</Localized> : option.name}
                            </ListItemText>
                        </MenuItem>
                    )}
                    {options.filter(f => f.checkOther).length !== 0 ? <Divider/> : null}
                    {options.filter(f => !f.checkOther).map(option => 
                        <MenuItem onClick={() => onUpdate(option.uniqueName)}>
                            {multiple ? <Checkbox checked={option.selected}/> : null }
                            <ListItemText>
                                {option.translationCode ? <Localized id={option.translationCode}>{option.name}</Localized> : option.name}
                            </ListItemText>
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>
        </>
    )
}
