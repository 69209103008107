import { Backdrop, CircularProgress, Typography } from "@mui/material";

export const LoadingOverlay = ({ isOpen, text = "Importing..." }: { isOpen: boolean; text?: string }) => {
    return (
        <Backdrop open={isOpen} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ marginLeft: 2 }}>{text}</Typography>
        </Backdrop>
    );
};
