import { Autocomplete, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent,  DialogContentText,  DialogTitle,  FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, Tab, TextField, Tooltip, Typography } from "@mui/material"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { alpha, styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Settings from '@mui/icons-material/Settings'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { addStructure, AddStructureCommand, addRootStructure, createStructure,  idle, selectAllStructureNodes, selectStructure, selectStructureNodeById, EditStructureCommand, updateStructure, editStructure, Structure, DeleteStructureCommand, deleteStructure, delStructure, setTreeSelectedNode, uncheckStructureNodes, checkStructureNodes, uncheckAllStructureNodes, } from "./structureSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Localized } from "@fluent/react";
import { LoadCompanies, LoadStructure } from "../../app/AppDataLoader";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { If } from "../../app/If";
import {selectRelationshipByStructureId} from "./structureRelationshipSlice";
import TranslateIcon from '@mui/icons-material/Translate';
import { postWithAuth } from "../../http";
import { TreeItem, TreeItemProps, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import LoadingButton from "@mui/lab/LoadingButton";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { store } from "../../app/store";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ModuleStructure } from "../modules/ModuleStructure";
import { Company, selectAllCompanies } from "../company/companiesSlice";
import { LoadingContainer } from "../../app/LoadingContainer";
import { CompanyStructure, loadCompanyStructure, saveCompanyStructure } from "../company/CompanyForm";
import { BottomAppBar } from "../../app/BottomAppBar";
import SaveIcon from '@mui/icons-material/Save'
import { Locale, selectSettings } from "../settings/settingsSlice";

const CustomTreeItem = React.forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} ref={ref} />
    ),
)

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.label}:hover`]: {
      '& button': {
          visibility: 'visible',
      }
  },
  [`& .${treeItemClasses.label}`]: {
      '& button': {
          visibility: 'hidden',
      }
  },
}));

interface StructureTreeItemProps {
    nodeId: AppId
    editable: boolean
    multiselect: boolean
    showCounter: boolean
    onNodeChecked?: (nodeId: AppId) => void
    onNodeUnhecked?: (nodeId: AppId) => void
}

const StructureTreeItem = ({ nodeId, editable, multiselect, showCounter, onNodeChecked, onNodeUnhecked }: StructureTreeItemProps) => {
    const dispatch = useAppDispatch()
    const structure = useAppSelector(state => selectStructureNodeById(state, nodeId)) 
    const rel = useAppSelector(state => selectRelationshipByStructureId(state, nodeId))
    const selection = useAppSelector(state => selectStructure(state).tree.multiselection)

    const nextSelectionState = { ...selection };

    const checked = selection[nodeId] === true;

    const indeterminate = rel?.succs.some(i => selection[i.structureId] !== undefined) && !rel?.succs.every(i => selection[i.structureId] === true);
    const allChildrenSelected = rel?.succs !== undefined && rel?.succs.length > 0 && rel?.succs.every((i) => selection[i.structureId] === true);
    const selectedChildrenCount = rel?.succs.filter((i) => selection[i.structureId] === true || selection[i.structureId] === "indeterminate").length || 0;
    const totalChildrenCount = rel?.succs.length || 0;

    const handleAddNodeClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        dispatch(addStructure(nodeId))
    }, [dispatch, nodeId])
    const handleEditButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        dispatch(editStructure(nodeId))
    }, [nodeId, dispatch])
    const handleDeleteButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        dispatch(delStructure(nodeId))
    }, [nodeId, dispatch])

    const checkAllChildren = (nodeId: string, checked: boolean) => {
        const structureNode = selectStructureNodeById(store.getState(), nodeId)
        const childIds = structureNode?.subStructureIds || [];
        childIds.forEach((childId) => {
            if (checked) {
                nextSelectionState[childId] = true;
                dispatch(checkStructureNodes([childId]));
            } else {
                delete nextSelectionState[childId]
                dispatch(uncheckStructureNodes([childId]));
            }
            checkAllChildren(childId, checked);
        });
    };

    const checkParentNodes = (nodeId: string) => {
        const structureNode = selectStructureNodeById(store.getState(), nodeId);
        
        if (!structureNode?.parentStructureId) return;

        const parentId = structureNode.parentStructureId;
        const parentStructure = selectStructureNodeById(store.getState(), parentId);
        const siblings = parentStructure?.subStructureIds || [];
        const allSiblingsChecked = siblings.every((siblingId) => nextSelectionState[siblingId] === true);
        const anySiblingChecked = siblings.some((siblingId) => nextSelectionState[siblingId] !== undefined);
    
        if (allSiblingsChecked) {
            nextSelectionState[parentId] = true;
            dispatch(checkStructureNodes([parentId]));
        } else if(anySiblingChecked) {
            nextSelectionState[parentId] = "indeterminate";
            dispatch(checkStructureNodes([parentId]));
        } else {
            delete nextSelectionState[parentId]
            dispatch(uncheckStructureNodes([parentId]));
        }
    
        checkParentNodes(parentId);
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            nextSelectionState[nodeId] = true;
            dispatch(checkStructureNodes([nodeId]))
            checkAllChildren(nodeId, true);
            checkParentNodes(nodeId)
            if (onNodeChecked) {
                onNodeChecked(nodeId)
            }
        } else {
            delete nextSelectionState[nodeId]
            dispatch(uncheckStructureNodes([nodeId]))
            checkAllChildren(nodeId, false);
            checkParentNodes(nodeId);
            if (onNodeUnhecked) {
                onNodeUnhecked(nodeId)
            }
        }
    }
    const handleCheckClick = (e: React.SyntheticEvent) => {
        e.stopPropagation()
    }

    if (structure === undefined) return null
    
    const { name, subStructureIds } = structure
    const hasAnyChild = subStructureIds.length > 0

    return <StyledTreeItem itemId={nodeId} label={
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
            pr: 0,
        }}>
            <If condition={multiselect}>
                <Checkbox 
                    size="medium" 
                    checked={checked || allChildrenSelected} 
                    indeterminate={indeterminate}
                    onChange={handleSelectChange} 
                    onClick={handleCheckClick}
                />
            </If>
            <Box component={hasAnyChild ? FolderOpenIcon : Settings} />
            <Typography sx={{ marginLeft: 1, marginRight: 4, }}>
                <StructureName structureId={structure.id} /> ({structure.code}) {showCounter && hasAnyChild && ` (${multiselect ? `${selectedChildrenCount}/` : ""}${totalChildrenCount})`}
            </Typography>
            <If condition={editable}>
                <Button size="small" onClick={handleAddNodeClick}><AddIcon /></Button>
                <Button size="small" onClick={handleEditButtonClick}><EditIcon /></Button>
            </If>
            <If condition={editable && !hasAnyChild}>
                <Button size="small" onClick={handleDeleteButtonClick}><DeleteIcon /></Button>
            </If>
        </Box>
    }>
        {subStructureIds.map(id => <StructureTreeItem 
                                        multiselect={multiselect} 
                                        editable={editable} 
                                        showCounter={showCounter}
                                        key={id} 
                                        nodeId={id} 
                                        onNodeChecked={onNodeChecked}
                                        onNodeUnhecked={onNodeUnhecked}
                                    />)}
    </StyledTreeItem>
}

const AddRootStructure = () => {
    const dispatch = useAppDispatch()
    const [name, setName] = useState("")
    const [name_EN, setName_EN] = useState("")
    const [code, setCode] = useState("")
    const [saving, setSaving] = useState(false)
    const [loadingTranslation, setLoadingTranslation] = useState(false)
    const [grantAccessToAllModules, setGrantAccessToAllModules] = useState<boolean>(true);
    const [grantAccessToAllCompanies, setGrantAccessToAllCompanies] = useState<boolean>(true);
    
    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [])
    const handleNameENChange: AppTextFieldHandler = useCallback(e => setName_EN(e.target.value), [])
    const handleCodeChange: AppTextFieldHandler = useCallback(e => setCode(e.target.value), [])
    const handleCancelClick = useCallback(() => dispatch(idle()), [dispatch])
    const handleGrantAccessToAllModuleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGrantAccessToAllModules(event.target.checked);
    }
    const handleGrantAccessToAllCompaniesChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGrantAccessToAllCompanies(event.target.checked);
    }

    const save = async () => {
        if (code && name) {
            setSaving(true)
            try {
                await dispatch(createStructure({
                    code,
                    name,
                    name_EN,
                    description: "",
                    description_EN: "",
                    parentStructureId: null,
                    subStructureIds: [],
                    grantAccessToAllCompanies,
                    grantAccessToAllModules
                }))
            } catch (error) {
                
            }
            setSaving(false)
            dispatch(idle())
        }
    }

    const handleTranslateToENClick = async () => {
        setLoadingTranslation(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: name,
                    LanguageTo: 'en-US'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
              
                const { payload } = result
                setName_EN(payload.text)
            }
        }
        finally {
            setLoadingTranslation(false)
        }
    }
    
    return <>
        <DialogTitle>
            <Localized id="structure-add">
                <span>Dodaj element struktury</span>
            </Localized>
        </DialogTitle>
        <DialogContent>
            <Stack sx={{ marginTop: 2 }} spacing={2}>
                <TextField 
                    label="Code"
                    value={code}
                    onChange={handleCodeChange}
                    />
                <TextField 
                     label={  
                        <Localized id="name-pl">
                            <span>Nazwa</span>
                        </Localized>
                    }
                    value={name}
                    onChange={handleNameChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <If condition={!loadingTranslation} otherwise={<CircularProgress />}>
                                    <Tooltip title={<Localized id="translate-to-en">Przetłumacz</Localized>}>
                                        <IconButton onClick={handleTranslateToENClick}>
                                            <TranslateIcon />
                                        </IconButton>
                                    </Tooltip>
                                </If>
                            </InputAdornment>
                        )
                    }}
                    />
                <TextField 
                    label={  
                        <Localized id="name-en">
                            <span>Nazwa</span>
                        </Localized>
                    }
                    value={name_EN}
                    onChange={handleNameENChange}
                    />
                <FormControlLabel label="Nadaj dostęp wszystkim modułom" control={
                    <Checkbox checked={grantAccessToAllModules} onChange={handleGrantAccessToAllModuleChange} />
                }/>
                <FormControlLabel label="Nadaj dostęp wszystkim firmom" control={
                    <Checkbox checked={grantAccessToAllCompanies} onChange={handleGrantAccessToAllCompaniesChange} />
                }/>
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={save}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelClick}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}

const AddStructureNode = () => {
    const dispatch = useAppDispatch()
    const { parentId: defaultParentId } = useAppSelector(state => selectStructure(state).command) as AddStructureCommand
    const allStructures = useAppSelector(selectAllStructureNodes)

    const [name, setName] = useState("")
    const [name_EN, setName_EN] = useState("")
    const [code, setCode] = useState("")
    const [parentId, setParentId] = useState(defaultParentId)

    const [saving, setSaving] = useState(false)
    const [loadingTranslation, setLoadingTranslation] = useState(false)
    const [grantAccessToAllModules, setGrantAccessToAllModules] = useState<boolean>(true);
    const [grantAccessToAllCompanies, setGrantAccessToAllCompanies] = useState<boolean>(true);

    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [])
    const handleNameENChange: AppTextFieldHandler = useCallback(e => setName_EN(e.target.value), [])
    const handleCodeChange: AppTextFieldHandler = useCallback(e => setCode(e.target.value), [])
    const handleParentChange = (event: SelectChangeEvent) => {
        setParentId(event.target.value);
    }
    const handleGrantAccessToAllModuleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGrantAccessToAllModules(event.target.checked);
    }
    const handleGrantAccessToAllCompaniesChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGrantAccessToAllCompanies(event.target.checked);
    }

    const handleCancelClick = useCallback(() => dispatch(idle()), [dispatch])
    const save = async () => {
        if (code && name) {
            setSaving(true)
            try {
                await dispatch(createStructure({
                    code,
                    name,
                    name_EN,
                    description: "",
                    description_EN: "",
                    parentStructureId: parentId,
                    subStructureIds: [],
                    grantAccessToAllCompanies,
                    grantAccessToAllModules
                }))
            } catch (error) {
                dispatch(showError("error"))
            }
            setSaving(false)
            dispatch(idle())
        }
    }

    const handleTranslateToPLClick = async () => {
        setLoadingTranslation(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: name_EN,
                    LanguageTo: 'pl'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
              
                const { payload } = result
                setName(payload.text)
               
            }
        }
        finally {
            setLoadingTranslation(false)
        }
    }

    const handleTranslateToENClick = async () => {
        setLoadingTranslation(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: name,
                    LanguageTo: 'en-US'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
              
                const { payload } = result
                setName_EN(payload.text)
            }
        }
        finally {
            setLoadingTranslation(false)
        }
    }

    return <>
        <DialogTitle>
            <Localized id="structure-add">
                <span>Dodaj element struktury</span>
            </Localized>
        </DialogTitle>
        <DialogContent>
            <Stack sx={{ marginTop: 2 }} spacing={2}>
                <FormControl>
                    <InputLabel>
                        <Localized id="structure-node-type">
                            <span>Typ węzła</span>
                        </Localized>
                    </InputLabel>
                    <Select 
                        value={parentId} 
                        onChange={handleParentChange}
                        label={
                            <Localized id="structure-parent">
                                <span>Element nadrzędny</span>
                            </Localized>
                        }
                    >{allStructures.map(({ id, name, code }) => <MenuItem key={id} value={id}>{`${name} (${code})`}</MenuItem>)}</Select>
                </FormControl>
                <TextField 
                    label="Code"
                    value={code}
                    onChange={handleCodeChange}
                    />
                <TextField 
                    label={  
                        <Localized id="name-pl">
                            <span>Nazwa</span>
                        </Localized>
                    }
                    value={name}
                    onChange={handleNameChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <If condition={!loadingTranslation} otherwise={<CircularProgress />}>
                                    <Tooltip title={<Localized id="translate-to-en">Przetłumacz</Localized>}>
                                        <IconButton onClick={handleTranslateToENClick}>
                                            <TranslateIcon />
                                        </IconButton>
                                    </Tooltip>
                                </If>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField 
                    label={  
                        <Localized id="name-en">
                            <span>Nazwa</span>
                        </Localized>
                    }
                    value={name_EN}
                    onChange={handleNameENChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <If condition={!loadingTranslation} otherwise={<CircularProgress />}>
                                    <Tooltip title={<Localized id="translate-to-pl">Przetłumacz</Localized>}>
                                        <IconButton onClick={handleTranslateToPLClick}>
                                            <TranslateIcon />
                                        </IconButton>
                                    </Tooltip>
                                </If>
                            </InputAdornment>
                        )
                    }}
                />
                <FormControlLabel label="Nadaj dostęp wszystkim modułom" control={
                    <Checkbox checked={grantAccessToAllModules} onChange={handleGrantAccessToAllModuleChange} />
                }/>
                <FormControlLabel label="Nadaj dostęp wszystkim firmom" control={
                    <Checkbox checked={grantAccessToAllCompanies} onChange={handleGrantAccessToAllCompaniesChange} />
                }/>
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={save}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelClick}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

const EditStructure = () => {
    const dispatch = useAppDispatch()
    const { nodeId } = useAppSelector(state => selectStructure(state).command) as EditStructureCommand
    const structure = useAppSelector(state => selectStructureNodeById(state, nodeId)) as Structure
    const { code: origCode, name: origName, name_EN: origNameEn, description: origDescription, description_EN: origDescription_EN} = structure

    const [code, setCode] = useState(origCode)
    const [name, setName] = useState(origName)
    const [name_EN, setName_EN] = useState(origNameEn)
    const [description, setDescription] = useState(origDescription)
    const [description_EN, setDescription_EN] = useState(origDescription_EN)
    const [loadingTranslation, setLoadingTranslation] = useState(false)

    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)

    const save = async () => {
        setValidationErrors(true)
        if (code && name) {
            setSaving(true)
            try {
                await dispatch(updateStructure({ 
                    ...structure,
                    code, 
                    name,
                    name_EN,
                    description,
                    description_EN
                }))
            } catch (error) {
                dispatch(showError("error"))
            }
            setSaving(false)
            dispatch(idle())
        }

    }

    const handleTranslateToPLClick = async () => {
        setLoadingTranslation(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: name_EN,
                    LanguageTo: 'pl'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
              
                const { payload } = result
                setName(payload.text)
               
            }
        }
        finally {
            setLoadingTranslation(false)
        }
    }

    const handleTranslateToENClick = async () => {
        setLoadingTranslation(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: name,
                    LanguageTo: 'en-US'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
              
                const { payload } = result
                setName_EN(payload.text)
            }
        }
        finally {
            setLoadingTranslation(false)
        }
    }

    const handleCancelClick = useCallback(() => {
        dispatch(idle())
    }, [dispatch])

    const handleCodeChange: AppTextFieldHandler = useCallback(e => setCode(e.target.value), [setCode])
    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [setName])
    const handleNameENChange: AppTextFieldHandler = useCallback(e => setName_EN(e.target.value), [setName])
    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => setDescription(e.target.value), [setDescription])
    const handleDescriptionENChange: AppTextFieldHandler = useCallback(e => setDescription_EN(e.target.value), [setDescription])

    const codeError = validationErrors && code === ""
    const nameError = validationErrors && name === ""

    return <>
        <DialogTitle>
            <Localized id="structure-edit">
                <span>Edycja elementu struktury</span>
            </Localized>
        </DialogTitle>
        <DialogContent>
            <Stack sx={{ marginTop: 2 }} spacing={4}>
                <TextField 
                    fullWidth
                    error={codeError}
                    helperText={codeError && <RequiredError />}
                    value={code}
                    onChange={handleCodeChange}
                    inputProps={{ maxLength: 20 }}    
                    label={
                        <Localized id="structure-code">
                            <span>Kod</span>
                        </Localized>
                    }
                />
                <TextField 
                    fullWidth
                    error={nameError}
                    helperText={nameError && <RequiredError />}
                    value={name}
                    inputProps={{ maxLength: 200 }}    
                    onChange={handleNameChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    label={
                        <Localized id="name-pl">
                            <span>Nazwa</span>
                        </Localized>
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <If condition={!loadingTranslation} otherwise={<CircularProgress />}>
                                    <Tooltip title={<Localized id="translate-to-en">Przetłumacz</Localized>}>
                                        <IconButton onClick={handleTranslateToENClick}>
                                            <TranslateIcon />
                                        </IconButton>
                                    </Tooltip>
                                </If>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField 
                    fullWidth
                    value={name_EN}
                    inputProps={{ maxLength: 200 }}    
                    onChange={handleNameENChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    label={
                        <Localized id="name-en">
                            <span>Nazwa</span>
                        </Localized>
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <If condition={!loadingTranslation} otherwise={<CircularProgress />}>
                                    <Tooltip title={<Localized id="translate-to-pl">Przetłumacz</Localized>}>
                                        <IconButton onClick={handleTranslateToPLClick}>
                                            <TranslateIcon />
                                        </IconButton>
                                    </Tooltip>
                                </If>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    fullWidth
                    multiline
                    value={description}
                    inputProps={{ maxLength: 1000 }}    
                    onChange={handleDescriptionChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    label={
                        <Localized id="description-pl">
                            <span>Opis</span>
                        </Localized>
                    }
                />
                <TextField
                    fullWidth
                    multiline
                    value={description_EN}
                    inputProps={{ maxLength: 1000 }}    
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={handleDescriptionENChange}
                    label={
                        <Localized id="description-en">
                            <span>Opis</span>
                        </Localized>
                    }
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={save}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelClick}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    
    </>
}

const DeleteStructure = () => {
    const dispatch = useAppDispatch()
    const { nodeId } = useAppSelector(state => selectStructure(state).command) as DeleteStructureCommand
    const [saving, setSaving] = useState(false)

    const handleDeleteClick = useCallback(async () => {
        setSaving(true)
        try {
            await dispatch(deleteStructure(nodeId))
        } catch (error) {
            dispatch(showError("error"))
        }
        setSaving(false)
        dispatch(idle())
    }, [nodeId, dispatch])
    const handleCancelClick = useCallback(() => {
        dispatch(idle())
    }, [dispatch])

    return <>
        <DialogTitle>
            <Localized id="delete">
                <span>Usuń</span>
            </Localized>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleDeleteClick}>
                <Localized id="delete">
                    <span>Usuń</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelClick}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}

const DialogContentDispatcher = () => {
    const { kind } = useAppSelector(state => selectStructure(state).command)
    switch (kind) {
        case "noCommand":
            return <></>
        case "addRootStructure":
            return <AddRootStructure />
        case "addStructure":
            return <AddStructureNode />
        case "editStructure":
            return <EditStructure />
        case "deleteStructure":
            return <DeleteStructure />
    }
}

export interface StructureTreeProps {
    multiselect      : boolean
    editable         : boolean
    showCounter      : boolean
    onNodeChecked?   : (nodeId: AppId) => void 
    onNodeUnchecked? : (nodeId: AppId) => void
}

type FormTabs = "structure" | "accessToStructure" | "companyAccessToStructure"
type StructureState = "empty" | "loading" | "loaded" | "error"

export const StructureTabs = () => {
    const dispatch = useAppDispatch();
    const allCompanies = useAppSelector(selectAllCompanies)

    const [company, setCompany] = useState<Company | undefined>(undefined)
    const [structure, setStructure] = useState<StructureState>("empty")
    const [tab, setTab] = useState<FormTabs>("structure");
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        const load = async (companyId: AppId) => {
            return await dispatch(loadCompanyStructure(companyId)).unwrap()
        }
        if (tab === "companyAccessToStructure" && structure === "empty" && company !== undefined) {
            setStructure("loading")
            load(company.id).then(x => {
                dispatch(uncheckAllStructureNodes())
                dispatch(checkStructureNodes(x.map(({ structureId }) => structureId)))
                setStructure("loaded")
            }).catch(() => {
                setStructure("error")
            })

        }
    }, [dispatch, tab, structure, company])

    const handleCompanyChange = useCallback((_: any, newCompany: Company | null) => {
        if (newCompany) {
            setCompany(newCompany)
            setStructure("empty");
        }
    }, [])

    const handleSaveClick = async () => {
        setSaving(true);
        try {
            if (company !== undefined && structure === "loaded") {
                const checkNodes = selectStructure(store.getState()).tree.multiselection
                const items: CompanyStructure[] = Object.entries(checkNodes).map(([structureId, _]) => {
                    return {
                        structureId,
                        companyId: company.id,
                    }
                })
                await dispatch(saveCompanyStructure([company.id, items]))
                dispatch(showSuccess("saved"));
            }
        } catch {
            dispatch(showError("error"));
        } finally {
            setSaving(false);
        }
    }
    
    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    return <Container sx={{
        height: 'calc(100vh - 200px)',
        overflowX: 'auto',
    }} component={Paper} maxWidth="lg">
        <TabContext value={tab}>
            <TabList onChange={handleTabChange}>
                <Tab
                    key={0}
                    value={"structure"}
                    label={<Localized id="structure">Struktura</Localized>} />
                <Tab
                    key={1}
                    value={"accessToStructure"}
                    label={<Localized id="structure-access">Dostęp modułów do struktury</Localized>} />
                <Tab
                    key={2}
                    value={"companyAccessToStructure"}
                    label={<Localized id="structure-company-access">Dostęp firm do struktury</Localized>} />
            </TabList>
            <TabPanel value="structure">
                <StructureTree editable={true} multiselect={false} showCounter={true} />
            </TabPanel>
            <TabPanel value="accessToStructure">
                <ModuleStructure />
            </TabPanel>
            <TabPanel value="companyAccessToStructure">
                <LoadCompanies component={<Skeleton />}>
                    <Autocomplete 
                        sx={{
                            width: "400px",
                        }}
                        fullWidth={false}
                        options={allCompanies}
                        getOptionLabel={({ name }) => name}
                        value={company ?? null}
                        onChange={handleCompanyChange}
                        renderInput={params =>
                            <TextField {...params} />
                        }
                    />
                </LoadCompanies>
                <If condition={company !== undefined} otherwise={<Localized id="structure-select-company">Wybierz firmę, aby zmodyfikować strukturę</Localized>}>
                    <If condition={structure === "loaded"} otherwise={<LoadingContainer/>}>
                        <LoadStructure component={<Skeleton />}>
                            <Box sx={{
                                maxHeight: 'calc(100vh - 420px)',
                                overflowY: 'auto',
                            }}>
                                <StructureTree editable={false} multiselect={true} showCounter={true}/>
                            </Box>
                        </LoadStructure>
                    </If>
                </If>
                <BottomAppBar>
                    <LoadingButton 
                        loading={saving}
                        disabled={company === undefined || structure !== "loaded"} 
                        variant="contained"
                        color="secondary" 
                        startIcon={<SaveIcon />}
                        onClick={handleSaveClick}
                    >
                        <Localized id="save">Zapisz</Localized>
                    </LoadingButton>
                </BottomAppBar>
            </TabPanel>
        </TabContext>
    </Container>
}

export const StructureTree = (props: StructureTreeProps) => {
    return <LoadStructure component={<Skeleton />}>
        <StructureTreeComponent {...props}/>
    </LoadStructure>
}

export const StructureTreeComponent = ({ multiselect, editable, showCounter, onNodeChecked, onNodeUnchecked }: StructureTreeProps) => {
    const dispatch = useAppDispatch()
    const rootStructures = useAppSelector(state => selectAllStructureNodes(state).filter(x => x.parentStructureId === null))
    const allNonLeafStructureIds = useAppSelector(
        state => selectAllStructureNodes(state).filter(x => x.subStructureIds.length > 0)
            .map(({ id }) => id))
    const { kind: commandType } = useAppSelector(state => selectStructure(state).command)
    const { selected } = useAppSelector(state => selectStructure(state).tree)
    const [expanded, setExpanded] = useState<AppId[]>([])

    const handleAddRootCategoryButtonClick = useCallback(() => {
        dispatch(addRootStructure())
    }, [dispatch])
    const handleExpandAllButtonClick = useCallback(() => {
        setExpanded(allNonLeafStructureIds)
    }, [allNonLeafStructureIds])
    const handleCollapseAllButtonClick = useCallback(() => {
        setExpanded([])
    }, [])
    const handleNodeToggle = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds)
    }, [])
    const handleNodeSelect = useCallback((_, nodeId: string | null) => {
        if (nodeId) {
            dispatch(setTreeSelectedNode(nodeId))
        }
    }, [dispatch])

    return <>
        <Stack spacing={2}>
            <Box>
                <Button onClick={handleExpandAllButtonClick}>
                    <Localized id="structure-expand-all">
                        <span>Rozwiń wszystko</span>
                    </Localized>
                </Button>
                <Button onClick={handleCollapseAllButtonClick}>
                    <Localized id="structure-collapse-all">
                        <span>Zwiń wszystko</span>
                    </Localized>
                </Button>
                <If condition={editable}>
                    <Button onClick={handleAddRootCategoryButtonClick}>
                        <Localized id="structure-add-root-category">
                            <span>Dodaj główną kategorię</span>
                        </Localized>
                    </Button>
                </If>
            </Box>
            <SimpleTreeView 
                slots = {{ collapseIcon: ArrowDropDownIcon, expandIcon: ArrowRightIcon }}
                onExpandedItemsChange={handleNodeToggle}
                expandedItems={expanded}
                selectedItems={selected ?? ""}
                multiSelect={false}
                onSelectedItemsChange={handleNodeSelect}
            >
                {rootStructures.map(struct => 
                                        <StructureTreeItem 
                                            multiselect={multiselect} 
                                            editable={editable} 
                                            showCounter={showCounter}
                                            key={struct.id} 
                                            nodeId={struct.id} 
                                            onNodeChecked={onNodeChecked} 
                                            onNodeUnhecked={onNodeUnchecked}
                                        />)}
            </SimpleTreeView>
        </Stack>
        <If condition={editable}>
            <Dialog 
                open={commandType !== 'noCommand'}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogContentDispatcher />
            </Dialog>
        </If>
    </>;
}

export const StructureNodeSelector = () => {
    return <Container sx={{
        height: 'calc(100vh - 200px)',
        overflowX: 'auto',
    }} component={Paper} maxWidth="lg">
        <LoadStructure component={<Skeleton />}>
            <StructureTreeComponent multiselect={false} editable={false} showCounter={false}/>
        </LoadStructure>
    </Container>
}

export const StructureName = ({ structureId }: { structureId: AppId}) => {
    const structure = useAppSelector(state => selectStructureNodeById(state, structureId))
    const { locale } = useAppSelector(selectSettings)

    if (structure === undefined) return <Localized id="not-found-or-unaccessible">Nie znaleziono lub niedostępny</Localized>

    const { name, name_EN } = structure

    let displayName: string
    switch (locale) {
        case 'en-US':
            displayName = name_EN ? name_EN : name
            break
        case 'pl':
            displayName = name
            break
        default:
            displayName = name_EN ? name_EN : name
            break
    }

    return <>{displayName}</>
}

export const getStructureName = (id: AppId, lang?: Locale) => {
    const locale = lang ? lang : selectSettings(store.getState()).locale
    const structure = selectStructureNodeById(store.getState(), id)

    if (structure === undefined) return 'Not found'

    const { name, name_EN } = structure

    switch (locale) {
        case 'en-US':
            return name_EN ? name_EN : name
        case 'pl':
            return name
        default:
            return name_EN ? name_EN : name
    }

}

export const StructureMenuItem = ({ id }: { id: AppId }) => {
    const structure = useAppSelector(state => selectStructureNodeById(state, id))

    if(structure=== undefined){
        return <span>{`Nie znaleziono struktury : ${id}`}</span>
    }
    else{
        return <span><StructureName structureId={id} /></span>
    }
}
