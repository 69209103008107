import { Localized } from "@fluent/react";
import { Box, Button, Divider, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { useEffect, useState } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateTimePicker } from "@mui/x-date-pickers";

const InvalidDateError = () => <Localized id="field-invalid-date"><span>Data jest nieprawidłowa</span></Localized>

type DateTypes = "all" | "30days" | "currentMonth" | "previousMonth";

interface DateFilterMenuProps {
    allowAllOption?: boolean;
    defaultOption?: DateTypes;
    currentOption?: DateTypes;
    selectYear?: boolean;
    onSubmit: (date: { dateFrom: Date | number | "all", dateTo: Date | number | "all" }) => void;
}

export const DateFilterMenu = ({ allowAllOption, defaultOption, currentOption, selectYear, onSubmit }:DateFilterMenuProps) => {
    const currentDate = new Date();
    var dateFromFilter = new Date();
    dateFromFilter.setDate(currentDate.getDate() - 30);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const [validationErrors, setValidationErrors] = useState(false);
    const [dateFrom, setDateFrom] = useState(dateFromFilter)
    const [dateTo, setDateTo] = useState(new Date())
    const [type, setType] = useState<DateTypes | "customDate">(defaultOption ?? '30days');

    const dateFromFilterError = validationErrors && (isNaN(dateFrom.getTime()) || dateFrom === null);
    const dateToFilterError = validationErrors && (isNaN(dateTo.getTime()) || dateTo === null);

    useEffect(() => {
        if(currentOption !== undefined) setType(currentOption);
    }, [currentOption]);
    
    const handleClick = (type) => {
        if(type === "all") {
            onSubmit({ dateFrom: "all", dateTo: "all" });
            setAnchorEl(null);
        } else if(type === '30days') {
            let newDateFrom = new Date();
            newDateFrom.setDate(currentDate.getDate() - 30);
            setDateFrom(newDateFrom);
            setDateTo(new Date());
            onSubmit({ dateFrom: newDateFrom, dateTo: new Date() });
            setAnchorEl(null);
        } else if(type === 'currentMonth') {
            let newDateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            setDateFrom(newDateFrom);
            setDateTo(new Date());
            onSubmit({ dateFrom: newDateFrom, dateTo: new Date() });
            setAnchorEl(null);
        } else if(type === "previousMonth") {
            let newDateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            let newDateTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            setDateFrom(newDateFrom);
            setDateTo(newDateTo);
            onSubmit({ dateFrom: newDateFrom, dateTo: newDateTo });
            setAnchorEl(null);
        }
        setType(type);
    }

    const handleChange = (date1:Date, date2:Date) => {
        setValidationErrors(true);
        if(!dateFromFilterError && !dateToFilterError) {
            setValidationErrors(false);
            if(selectYear) {
                onSubmit({ dateFrom: date1.getFullYear(), dateTo: date2.getFullYear() })
            } else {
                onSubmit({ dateFrom: date1, dateTo: date2 });
            }
        }
        setType("customDate");
    }

    const localizedType = () => {
        switch(type) {
            case "all":
                return "filters-date-all";
            case "30days":
                return "filters-date-last-30-days";
            case "currentMonth":
                return "filters-date-current-month";
            case "previousMonth":
                return "filters-date-previous-month";
            case "customDate":
                return "filters-date-custom-date";
        }
    }

    return (
        <>
            <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget) } variant="text" startIcon={<CalendarMonthIcon/>}>
                <Localized id={localizedType()}>_Cały okres</Localized>
            </Button>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null) }>
                <MenuList>
                    {(allowAllOption ? <MenuItem onClick={() => handleClick("all") }>
                        <ListItemText>
                            <Localized id="filters-date-all">_Cały okrees</Localized>
                        </ListItemText>
                    </MenuItem> : null)}
                    {(!selectYear ? <><MenuItem onClick={() => handleClick("30days") }>
                        <ListItemText>
                            <Localized id="filters-date-last-30-days">_Ostatnie 30 dni</Localized>
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleClick("currentMonth") }>
                        <ListItemText>
                            <Localized id="filters-date-current-month">_Bieżący miesiąc</Localized>
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleClick("previousMonth") }>
                        <ListItemText>
                            <Localized id="filters-date-previous-month">_Poprzedni miesiąc</Localized>
                        </ListItemText>
                    </MenuItem></> : null)}
                    <Divider />
                    <MenuItem>
                        <Box display="flex" flexDirection="column" width="100%" gap={2}>
                            <ListItemText>
                                <Localized id="filters-date-custom-date">_Własna data</Localized>
                            </ListItemText>
                            <DateTimePicker
                                label={<Localized id="system-events-from">Od</Localized>}
                                views={selectYear ? ['year'] : ['year', 'month', 'day']}
                                value={dateFrom}
                                slotProps={{
                                textField: {
                                    helperText: dateFromFilterError ? <InvalidDateError /> : ""
                                }
                                }}
                                onChange={(newVal) => { newVal && setDateFrom(newVal); newVal && handleChange(newVal, dateTo); } }
                            />
                            <DateTimePicker
                                label={<Localized id="system-events-to">Do</Localized>}
                                views={selectYear ? ['year'] : ['year', 'month', 'day']}
                                value={dateTo}
                                slotProps={{
                                textField: {
                                    helperText: dateToFilterError ? <InvalidDateError /> : ""
                                }
                                }}
                                onChange={(newVal) => { newVal && setDateTo(newVal); newVal && handleChange(dateFrom, newVal); } }
                            />
                        </Box>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}